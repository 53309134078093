@import "~assets/scss/variables";

.mat-progress-spinner {
    @mixin progress-circle-color($primary-color, $secondary-color) {
        svg circle {
            stroke: $primary-color;
            fill: $secondary-color;
        }
    }

    @each $name, $value in $progress-circle-map {
        &--#{$name} {
            @include progress-circle-color(map-get($value, base), map-get($value, secondary));
        }
    }
}
