@import '~assets/scss/variables';
@import '~assets/scss/mixins/transform';
@import '~assets/scss/mixins/fonts';
@import '~assets/scss/mixins/border';
@import '~assets/scss/mixins/assignment';

$textColor: var(--text-color, $boom-ho-secondary);

.document-matching-block {
    $padding: 0.5rem 2rem;
    $border-radius: 0.4rem;

    &__head,
    &__body {
        display: flex;
        flex-direction: row;
    }

    &__body {
        @include border-radius($border-radius);
        overflow: hidden;
    }

    &__answers {
        $margin: 1rem;

        display: flex;
        flex-direction: column;
        margin: $margin 0;
        gap: $margin;
    }

    &__answer {
        @include border-radius($border-radius);

        &--initial {
            @include assignment-answer-model($boom-ho-gray);
        }

        &--reviewed {
            @include assignment-answer-model($boom-vo-primary);
        }

        &--correct {
            @include assignment-answer-model($boom-ho-orange);
        }
    }

    &__head {
        border: 0.1rem solid $boom-ho-matching-border-color;
        border-bottom: 0;
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
    }

    &__head-item,
    &__matches,
    &__choices {
        flex: 1;
    }

    &__choices,
    &__matches {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
    }

    &__head-item {
        padding:  1.5rem 2rem;
        @include poppins-bold(1.6rem);

        &:not(:last-child) {
            border-right: 0.1rem solid $boom-ho-matching-border-color;
        }
    }

    &__match,
    &__choice {
        padding: $padding;
        border: 0.1rem solid $boom-ho-matching-border-color;
        border-top-left-radius: 0.4rem;

        &--text:before {
            font-family: boomho, serif;
            content: "";
            font-size: 1.4rem;
            color: #d9d9d9;
            display: flex;
            align-items: center;
            position: absolute;
            right: 1.6rem;
            height: 100%;
            z-index: 2;
        }

        &:not(:first-child) {
            margin-top: -0.1rem;
        }

        &--image {
            padding: 1rem;
            display: flex;
            flex-direction: column;
            justify-content: center;

            img {
                border: 0.1rem solid $boom-ho-secondary;
                border-radius: $border-radius;
                max-width: 100%;
            }
        }
    }

    &__match {
        display: flex;
        align-items: center;
        @include poppins-medium(1.6rem, 2.2rem);
        color: $textColor;
        background: $boom-ho-white;
        border-right: 0;
        position: relative;

        &:not(:last-child) {
            border-bottom-left-radius: 0.4rem;
        }

        .document-content-media__container img {
            border-radius: 0;
            max-width: 100%;
        }

        &:first-child {
            border-top-left-radius: $border-radius;
        }
        &:last-child {
            border-bottom-left-radius: $border-radius;
        }

        .document-content-media__container {
            margin: 0;
        }
    }

    &__choice {
        display: flex;
        align-items: center;
        background-color: $boom-ho-white;
        position: relative;
        border-bottom-right-radius: 0.4rem;
        border-top-right-radius: 0.4rem;
        $choice: &;

        &:not(#{$choice}--disabled) {
            @include cursor-grab();
        }

        &:last-child {
            border-bottom-right-radius: 0.4rem;
        }

        &:not(&--disabled):hover, &.gu-transit, &.gu-mirror {
            background: $boom-ho-white;

            &:before {
                color: $textColor;
            }
        }

        span {
            @include user-select(none);
        }
    }

    figure {
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0;
        margin-inline-end: 0;
    }
}
