@import '~assets/scss/variables';
@import '~assets/scss/mixins/border';

$document-input-inline-border-width: 0.1rem;

// theming
$borderColor: var(--text-color, $boom-ho-secondary);
$line-height: 4.5rem;

.document-select-block .document__input-inline .input-inline--correct-answer {
    display: none;
}

.document-select-block.document-select-block__correct .document__input-inline .input-inline--correct-answer {
    display: inline-block;
}

.document__input-inline {
    margin: 0.1rem 0;
    display: inline-flex;
    line-height: 4.6rem;
    flex-wrap: wrap;
    gap: 0.5rem;
    vertical-align: middle;

    .input-inline {
        position: relative;
        background-color: $boom-ho-white;
        border: $boom-ho-inline-input-border-color;
        box-shadow: inset 0 -0.2rem 0 #DDDDDD;
        border-radius: 0.4rem;
        min-width: 12rem;
        display: inline-block;
        width: auto;
        outline: 0;
        padding: 0 1.2rem;
        margin: 0.5rem 0;
        min-height: $line-height;

        &:focus,
        &:hover {
            box-shadow: inset 0px -2px 0px $borderColor;
        }

        &--correct-answer,
        &--reviewed-answer,
        &--initial-answer {
            display: inline-block;
            min-width: 9rem;

            &:before {
                @include border-radius(0.3rem);
                content: attr(data-label);
                white-space: nowrap;
                position: absolute;
                font-size: 0.75rem;
                text-transform: uppercase;
                display: block;
                line-height: 1.4rem;
                padding: 0 .4rem;
                top: -0.7rem;
                left: 0.4rem;
                color: #FFF;
            }
        }

        &--correct-answer {
            color: $boom-ho-orange;
            border-color: $boom-ho-orange !important;

            &:before {
                background-color: $boom-ho-orange;
            }

            &:hover {
                box-shadow: inset 0px -2px 0px $boom-ho-orange;
            }
        }

        &--reviewed-answer {
            color: $boom-vo-primary;
            border-color: $boom-vo-primary !important;

            &:before {
                background-color: $boom-vo-primary;
            }

            &:hover {
                box-shadow: inset 0px -2px 0px $boom-vo-primary;
            }
        }

        &--initial-answer {
            color: $boom-ho-gray;
            border-color: $boom-ho-gray !important;

            &:before {
                background-color: $boom-ho-gray;
            }

            &:hover {
                box-shadow: inset 0px -2px 0px $boom-ho-gray;
            }
        }

        &__handed-in-answer {

            &--incorrect {
                color: $boom-ho-red;
                border-color: $boom-ho-red !important;
                background-color: $boom-ho-red-question;
            }

            &--correct {
                color: $boom-ho-green;
                border-color: $boom-ho-green !important;
                background-color: $boom-ho-green-question;
            }
        }
    }

    &--has-value .input-inline .input-inline__correct-answer {
        border-top-color: $boom-ho-gray-lighter;
        border-left-color: $boom-ho-gray-lighter;
        border-right-color: $boom-ho-gray-lighter;
    }
}
