@import '~assets/scss/mixins/fonts';

@include font-face(Poppins, '../../fonts/poppins/Poppins-Regular.ttf', 400);
@include font-face(Poppins, '../../fonts/poppins/Poppins-Medium.ttf', 500);
@include font-face(Poppins, '../../fonts/poppins/Poppins-SemiBold.ttf', 600);
@include font-face(Poppins, '../../fonts/poppins/Poppins-Bold.ttf', 700);

// Montserrat Alternatives
@include font-face(MontserratAlternates, '../../fonts/MontserratAlternates/MontserratAlternates-Regular.ttf', 400);
@include font-face(MontserratAlternates, '../../fonts/MontserratAlternates/MontserratAlternates-Medium.ttf', 500);
@include font-face(MontserratAlternates, '../../fonts/MontserratAlternates/MontserratAlternates-SemiBold.ttf', 600);
@include font-face(MontserratAlternates, '../../fonts/MontserratAlternates/MontserratAlternates-Bold.ttf', 700);

// Roboto
@include font-face(Roboto, '../../fonts/Roboto/Roboto-Regular.ttf', 400);
@include font-face(Roboto, '../../fonts/Roboto/Roboto-Medium.ttf', 500);
@include font-face(Roboto, '../../fonts/Roboto/Roboto-Medium.ttf', 600);
@include font-face(Roboto, '../../fonts/Roboto/Roboto-Bold.ttf', 700);
