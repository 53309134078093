@import '~@angular/flex-layout/mq';
@import '~assets/scss/variables';
@import '~assets/scss/mixins/flex';
@import '~assets/scss/mixins/border';
@import '~assets/scss/mixins/color';
@import 'include-media';

@mixin container-absolute($top: 0, $right: 0, $bottom: 0, $left: 0) {
    position: absolute;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
}

@mixin container-border-radii($border-radius) {
    &--border-radius-top-right {
        @include border-radius-top-right($border-radius);
    }

    &--border-radius-bottom-right {
        @include border-radius-bottom-right($border-radius);
    }

    &--border-radius-top-bottom-right {
        @include media(">phone") {
            @include border-radius-right($border-radius);
        }
    }

    &--border-radius-top-right,
    &--border-radius-bottom-right,
    &--border-radius-top-bottom-right {
        min-width: $border-radius;
        min-height: $border-radius;
    }

    &--offset-next {
        // Any wrapper that requires a different bottom margin should keep the offset mode in mind
        > .container__wrapper:last-child {
            margin-bottom: $border-radius;
        }

        ~ .container {
            margin-top: -$border-radius;
        }
    }
}

@mixin container {
    $border-radius-small: map-get($border-radii, 'sm');
    $border-radius-medium: map-get($border-radii, 'md');

    .container {
        @include flex-column;
        @include container-border-radii($border-radius-small);
        background-color: $quaternaryColor;

        // Generate background color modifiers for the container
        @include color-bg-modifiers;

        @include layout-bp(gt-xs) {
            @include container-border-radii($border-radius-medium);
        }

        &__wrapper {
            margin-right: $layout-container-offset-xs;
            margin-left: $layout-container-offset-xs;

            @include layout-bp(gt-xs) {
                max-width: 116.8rem;
                margin-right: 16.8rem;
                margin-left: 26.4rem;
            }

            &--no-margin {
                max-width: 160rem;
                margin-right: 0;
                margin-left: 0;
            }

            &--less-margin {
                max-width: 144rem;
                margin-right: 3rem;
                margin-left: 13rem;
            }

            &--margin-top-bottom {
                margin-top: $border-radius-medium;
                margin-bottom: $border-radius-medium;
            }

            &--margin-bottom {
                margin-bottom: $border-radius-medium;
            }

            &--breadcrumb {
                margin-top: 4.5rem;
                margin-bottom: 5.6rem;
            }
        }

        &--table-of-contents-offset {
            // Add extra container spacing for when the 2nd navigation is opened
            .container__wrapper {
                @include layout-bp(xs) {
                    &--margin-top {
                        margin-top: 16.5rem;
                    }
                }

                @include layout-bp(gt-xs) {
                    margin-left: 36rem;

                    &--less-margin {
                        margin-left: 22.6rem;
                    }
                }

                &--navigation {
                    @include layout-bp(gt-xs) {
                        max-width: $layout-content-width;
                    }
                }
            }
        }

        &--no-background {
            background-color: transparent;
        }

        @content;
    }
}
