@import '~assets/scss/variables';

.check-box-input,
.radio-box-input {
    $check-box-lr-margin: 1rem;
    display: none;

    & + .check-box-label,
    & + .radio-box-label {
        align-items: center;
        position: relative;
        cursor: pointer;
        padding: 0;
    }

    // Box
    & + .check-box-label:before,
    & + .radio-box-label:before {
        content: '';
        margin: 0 $check-box-lr-margin;
        display: inline-block;
        vertical-align: text-top;
        width: 2rem;
        height: 2rem;
        background: $boom-ho-gray-lighter;
        border: 0.1rem solid #CAD1D8;
        box-sizing: border-box;
        border-radius: 0.4rem;
    }

    // radio box {
    & + .radio-box-label:before {
        border-radius: 100%;
    }

    // Box hover
    &:checked + .check-box-label:before,
    &:hover + .check-box-label:before,
    &:checked + .radio-box-label:before,
    &:hover + .radio-box-label:before {
        background: $select-box-background;
        border: 0.1rem solid rgba(27, 167, 100, 0.2);
        box-sizing: border-box;
    }

    // Checkmark
    &--checked + .check-box-label:after,
    &:checked + .check-box-label:after,
    &:checked + .radio-box-label:after {
        $checked-height: 0.2rem;
        $checked-height-offset: $checked-height / 2;

        content: '';
        position: absolute;
        left: $check-box-lr-margin + 0.5rem;
        top: calc((100% / 2) - #{$checked-height-offset});
        background: $boom-ho-green;
        width: 0.2rem;
        height: $checked-height;
        box-shadow: 0.2rem 0 0 $boom-ho-green,
        0.4rem 0 0 $boom-ho-green,
        0.4rem -0.2rem 0 $boom-ho-green,
        0.4rem -0.4rem 0 $boom-ho-green,
        0.4rem -0.6rem 0 $boom-ho-green,
        0.4rem -0.8rem 0 $boom-ho-green;
        transform: rotate(45deg);
    }
}
