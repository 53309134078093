.document-graphic-gap-match-block {
    .no-padding-left {
        padding-left: 0;
    }

    .graphic-gap-drop-zone-outer {
        border: 1px solid $boom-ho-secondary !important;
        border-radius: 5px;
        max-width: 50rem;
        padding: 10px;
        .graphic-gap-drop-zone-image-container {
            position: relative;

            img {
                width: 100%;
                height: 100%;
            }

            .graphic-gap-gap-container {
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
            }
            .graphic-gap-drop-zone {
                position: absolute;
                background-color: rgba(200,200,200,0.5);
                border: 1px solid $boom-ho-gray;
                min-height: 3rem;
                border-radius: 5px;
                .graphic-gap-choice {
                    width: auto;
                    opacity: 1;
                    min-width: 100%;
                    padding: 1px 4px;
                }
            }
        }
    }

    .graphic-gap-options-outer-container {
        background-color: $boom-ho-gray;
        min-height: 100px;
        padding: 5px;
    }

    .choices-drop-zone {
        margin-bottom: 10px; position: relative;
    }

    .choice-drop-zone-placeholder {
        min-height: 3rem;
        background-color: $boom-ho-gray;
        border: 0.1rem solid transparent;
        border-radius: 0.5rem;
        width: 100%;
        text-align: center;
        line-height: 3rem;
        opacity: 0.7;
    }

    .graphic-gap-choice {
        text-align: center;
        border: 0.1rem solid $boom-ho-secondary !important;
        border-radius: 5px;
        background-color: white;
        padding: 0.3rem;
        margin-bottom: 0;
        &.graphic-gap-choice-answer {
            width: auto;
        }
        position: absolute;
        width: 100%;
        top: 0;
    }

    .graphic-gap-choice-index {
        display: none;
    }

    .document__question-answer, .document__question-handed-in, .document__question-initial-answer {
        padding: 0;
        .graphic-gap-drop-zone-outer {
            border: 0 none !important;
            .graphic-gap-choice {
                opacity: 1 !important;
            }
        }
    }

    .document__question-answer {
        .graphic-gap-choice {
            border-color: $boom-ho-orange !important;
        }
    }

    .document__question-handed-in {
        .graphic-gap-choice {
            border-color: $boom-ho-primary !important;
        }
    }

    .document__question-initial-answer {
        .graphic-gap-choice {
            border-color: $boom-ho-gray !important;
        }
    }

    .document-graphic-gap-match-handed-in {
        .document-graphic-gap-match-choice-zones {
            margin-top: 40px;
        }
    }

    .cdk-drag-placeholder {
        opacity: 0;
    }

    .cdk-drag-animating {
        border-radius: 50px !important;
        transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
    }


    .document-graphic-gap-match {
        &-correct {
            position: relative;
            margin: 1rem 0;

            &:before {
                @include label-style;
                background-color: $boom-ho-orange;
            }

            .graphic-gap-drop-zone-outer {
                border: 0.1rem solid $boom-ho-orange !important;
                background-color: $boom-ho-white;
            }
        }

        &-reviewed {
            position: relative;
            margin: 1rem 0;

            &:before {
                @include label-style;
                background-color: $boom-vo-primary;
            }

            .graphic-gap-drop-zone-outer {
                border: 0.1rem solid $boom-vo-primary !important;
                background-color: $boom-ho-white;
            }
        }

        &-initial {
            position: relative;
            margin: 1rem 0;

            &:before {
                @include label-style;
                background-color: $boom-ho-gray;
            }

            .graphic-gap-drop-zone-outer {
                border: 0.1rem solid $boom-ho-gray !important;
                background-color: $boom-ho-white;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .no-padding-left {
        padding-left: 10px;
    }
    .document-graphic-gap-match-handed-in {
        .document-graphic-gap-match-choice-zones {
            margin-top: 14px;
        }
    }
}

.graphic-gap-choice {
    transition: none;
    &.cdk-drag-preview {
        box-sizing: border-box;
        text-align: center;
        border: 1px solid $boom-ho-secondary;
        border-radius: 5px;
        background-color: white;
        padding: 3px;
        margin-bottom: 3px;
        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
        0 8px 10px 1px rgba(0, 0, 0, 0.14),
        0 3px 14px 2px rgba(0, 0, 0, 0.12);
        position: static;
        .graphic-gap-choice-index {
            display: none;
        }
    }
}

.assignment-answer__wrapper {
    .graphic-gap-drop-zone-outer {
        max-width: 42rem;
    }
}
