@import '~assets/scss/font';
@import "~assets/scss/vendors/redactor/_scss/variables";


// Layout
$layout-container-offset: 3.2rem;
$layout-container-offset-xs: 2.4rem;
$layout-content-width: 77.6rem;

// Breakpoints, script breaks if not set to px!
$breakpoints: (phone: 320px, tablet: 768px, desktop: 1024px);

// MontserratAlternates
$montserrat-alternates-Regular: MontserratAlternates-Regular, 'sans-serif';
$montserrat-alternates-bold: MontserratAlternates-Bold-700, 'sans-serif';
$montserrat-alternates-semi-bold: MontserratAlternates-Bold-600, 'sans-serif';

// General colors
$boom-overlay: #00000052; // Same as material

// ho-colors
$boom-ho-primary: #08C9CA;
$boom-ho-primary-organization: rgba(8, 201, 202, 0.16);
$boom-ho-primary-rgba: rgba(8, 201, 202, 0.1);
$boom-ho-primary-lighter: #E6FAFA;
$boom-ho-secondary: #002439;
$boom-ho-white: #FFFFFF;
$boom-ho-white-transparent: rgba(255, 255, 255, 0.32);
$boom-ho-white-transparent-dark: rgba(255, 255, 255, 0.8);
$boom-ho-black: #000000;
$boom-ho-green: #1BA764;
$boom-ho-green-question: rgb(27 167 100 / 11%);
$boom-ho-orange: #FF8947;
$boom-ho-orange-select-box: #fddfcf;
$boom-ho-orange-rgba: rgba(255, 137, 71, 0.1);
$boom-ho-purple: #7B61FF;
$boom-ho-purple-rgba: rgba(123, 97, 255, 0.1);
$boom-ho-light-blue: #d6e9fd;
$boom-ho-blue: #0075F2;
$boom-ho-dark-blue: #005cbf;
$boom-ho-teal: #096B72;
$boom-ho-bordeaux: #BA324F;
$boom-ho-royal: #38369A;
$boom-ho-gray: #95a3ac;
$boom-ho-gray-dark: #6A747A;
$boom-ho-gray-darker: #E7E8E9;
$boom-ho-gray-dark-rgba: rgba(106, 116, 122, 0.4);
$boom-ho-gray-lighters: #DDDDDD;
$boom-ho-gray-light: #E5E5E5;
$boom-ho-gray-lighter: #F2F2F2;
$boom-ho-gray-lightest: #f8f8f8;
$boom-ho-gray-border: #CAD1D8;
$boom-ho-red: #F14242;
$boom-ho-red-question: #ff00001c;
$boom-ho-red-light: #FF8F8F;
$boom-ho-rgba-light: rgba(0, 0, 0, 0.12);
$boom-ho-hover: rgba(0, 0, 0, 0.25);
$boom-ho-hover-dark: rgba(0, 0, 0, 0.35);
$boom-ho-close: rgba(0, 36, 57, 0.08);
$boom-vo-hover-background: #43A5C3;
$boom-ho-organization: #DFE2E4;
$boom-ho-inactive: #F0F1F2;
$boom-ho-messages: #EA5C15;
$boom-ho-messages-bullet: #F14242;
$boom-ho-licenses-manage: #8997A0;
$boom-ho-exam-score-success: #e3f4ec;
$boom-ho-exam-score-circle-success: #c4e2d2;
$boom-ho-exam-score-failed: #fdf0f0;
$boom-ho-exam-score-circle-failed: #fbd7d7;
$boom-ho-exam-score-close-exam: rgb(255 255 255 / 63%);
$boom-ho-agoog: #A71878;
$boom-ho-agoog-bg: rgb(167 24 120 / 8%);

// vo-colors
$boom-vo-primary: #3496B4;

// Color vars
$primaryColor: var(--primary-color, $boom-ho-white);
$primaryColorRgba: var(--primary-color-rgba, rgba(255, 255, 255, 0.1));
$secondaryColor: var(--secondary-color, $boom-ho-white);
$tertiaryColor: var(--tertiary-color, $boom-ho-white);
$quaternaryColor: var(--quaternary-color, $boom-ho-gray-lightest);
$primaryAdjustedColor: var(--primary-adjusted-color, $boom-ho-gray-lighter);
$primaryAdjustedColorAlt: var(--primary-adjusted-color-alt, $boom-ho-gray);
$primaryTextColor: var(--primary-text-color, $boom-ho-gray);
$primaryTextAdjustedColor: var(--primary-text-adjusted-color, $boom-ho-gray);
$primaryTextHoverColor: var(--primary-text-color, $boom-ho-primary);
$cardTextColor: var(--card-text-color, $boom-ho-secondary);

$color-map: (
    'primary': $boom-ho-primary,
    'secondary': $boom-ho-secondary,
    'quaternary': $quaternaryColor,
    'white': $boom-ho-white,
    'black': $boom-ho-black,
    'green': $boom-ho-green,
    'orange': $boom-ho-orange,
    'purple': $boom-ho-purple,
    'blue': $boom-ho-blue,
    'dark-blue': $boom-ho-dark-blue,
    'teal': $boom-ho-teal,
    'bordeaux': $boom-ho-bordeaux,
    'royal': $boom-ho-royal,
    'gray-dark': $boom-ho-gray-dark,
    'gray': $boom-ho-gray,
    'gray-light': $boom-ho-gray-light,
    'gray-lighter': $boom-ho-gray-lighter,
    'gray-lightest': $boom-ho-gray-lightest,
    'red': $boom-ho-red,
    'red-light': $boom-ho-red-light,
    'agoog': $boom-ho-agoog,
    'transparent': transparent,

    // Color variables
    'primary-var': $primaryColor,
    'primary-adjusted-var': $primaryAdjustedColor,

    // @deprecated: use colors instead
    'document': $boom-ho-orange,
    'assignment': $boom-ho-primary,
    'exam': $boom-ho-purple,
    'vo': $boom-vo-primary,
);

// Element specific color
$boom-ho-horizontal: #e0e0e0;
$boom-ho-study: $boom-ho-orange;
$boom-ho-exam: $boom-ho-purple;
$boom-ho-transparent: transparent;
$boom-ho-signal-red: #F14242;
$boom-ho-icon-notification: #FF0000;
$text-gray: $boom-ho-gray-dark;
$background-disabled: #D3D3D3;
$boom-ho-profile: #E3E5E7;
$boom-ho-mark: #E1ECE7;
$boom-ho-case: #e3e9ee;
$boom-ho-case-divider: rgba(0, 117, 242, 0.1);
$boom-ho-inner-background: rgba(27, 167, 100, 0.16);
$boom-ho-box: rgba(0, 117, 242, 0.16);
$boom-ho-icon-border: rgba(27, 167, 100, 0.1);
$boom-ho-icon-background: #E6FAFA;
$boom-ho-question-answer: rgba(242, 242, 242, 1);
$boom-ho-textarea-question: $boom-ho-gray-lightest;
$boom-ho-sources: $boom-ho-gray-lightest;
$boom-ho-textarea-question-border: $boom-ho-gray-lighters;
$boom-ho-gold: #FFA24B;
$boom-ho-border-assignment: #EEEEEE;
$boom-ho-rate-devider: #DFE9F2;
$boom-ho-orange-light: #FFEBE2;
$boom-ho-primary-light: #D8F6F6;
$boom-ho-purple-light: #E6E3F2;
$boom-ho-link: #1D6ADE;
$boom-ho-filter: $boom-ho-rgba-light;
$boom-ho-transparent-button: rgba(0, 36, 57, 0.24);
$boom-ho-search: #BEC6CA;
$boom-ho-overlay: #EEF6F6;
$boom-ho-search-background-color: #EEEEEE;
$boom-ho-inline-input-border-color: 0.1rem solid rgba(55, 56, 90, 0.12);
$boom-box-shadow: 0 0.1rem 0.2rem rgb(0 0 0 / 12%);
$ebook-type-text-color: #99A7B0;
$boom-ho-canvas-border-color: $boom-ho-gray-lighters;
$boom-ho-canvas-box-shadow: 0 0.1rem 0.2rem rgb(0 0 0 / 12%);
$boom-ho-select-inline-boxshadow: $boom-ho-gray-lighters;
$boom-ho-select-inline-border: rgba(55, 56, 90, 0.12);
$boom-ho-matching-border-color: $boom-ho-gray-lighters;
$boom-ho-matching-drag-handle-color: #D9D9D9;
$boom-ho-assignment-background-color: #ececec;
$boom-ho-assignment-box-shadow: 0 0.1rem 0.2rem rgb(0 0 0 / 12%);
$manage-licenses-min-height: 5.6rem;

// Progress settings
$boom-ho-progress-icon-background: #e5e0e1;
$boom-ho-progress-icon-path-1: #6d7278;
$boom-ho-progress-icon-path-2: #a9a9ad;
$progress-entry-width: 5rem;
$progress-entry-height: 8rem;

// sidebar
$sidebar-box-shadow: 0.2rem 0 0.4rem #0000000f;
$sidebar-size-xs: 6.9rem;
$sidebar-second-background-color: $boom-ho-dark-blue;
$sidebar-blue-button-hover: #0354A7;
$sidebar-icon-circle: #006DDF;
$sidebar-item-title-color: #5793D5;

// Redactor
$redactor-box-min-height: 10.7rem;
$redactor-toolbar-height: 4.5rem;
$redactor-re-toggle-toolbar: 4.4rem;
$redactor-toolbar-collapsed: 3rem;
$redactor-toolbar-line-height: 2.9rem;
$redactor-toolbar--collapsed-padding-right: 5rem;
$redactor-toolbar-border-color: $boom-ho-textarea-question-border;
$redactor-toolbar-border-radius: 0.8rem;

// sources
$sources-type-text-color: #99A7B0;

// module menu
$module-menu-checked-background: #EDF8F3;

// group menu
$group-menu-checked-background: $module-menu-checked-background;

// Select box
$select-box-background: #D1EBDF;
$select-box-checked: #1da664;

// profile
$profile-avatar-color: #BFC3C6;

// progress
$progress-circle-primary: $boom-ho-primary;
$progress-circle-vo-primary: $boom-vo-primary;
$progress-circle-check-vo: #FFA14B;
$progress-circle-light-primary: $boom-ho-primary-light;
$progress-bar-background-color: #EEEEEE;
$progress-circle-title-color: $boom-ho-secondary;
$progress-circle-orange: #FFA675;
$progress-circle-light-orange: $boom-ho-orange-light;
$progress-circle-light-red: #F2BABA;
$progress-circle-red: $boom-ho-signal-red;
$progress-circle-light-blue: #CFE2E2;
$progress-circle-purple: $boom-ho-exam;
$progress-circle-green: $boom-ho-green;
$progress-circle-light-purple: $boom-ho-purple-light;
$progress-circle-default-size: 5rem;
$progress-circle-large-size: 7.2rem;
$progress-circle-small-size: 4.8rem;
$progress-circle-default-font-size: 1.6rem;
$progress-circle-small-font-size: 1.3rem;
$progress-status-dot-green: #8BDF94;
$progress-status-dot-red: $boom-ho-red;
$progress-status-dot-orange: $progress-circle-check-vo;
$progress-status-dot-gray: $boom-ho-gray-dark;
$progress-border-size: 0.1rem;
$progress-border: $progress-border-size solid #C8CACC;
$progress-circle-no-value-bg: #F4F6F7;
$progress-circle-no-value-dark-mode-bg: #E9EAEB;

// Status dot check
$progress-status-dot-check-n: #FFA14B;
$progress-status-dot-check-g: $boom-ho-green;
$progress-status-dot-check-v: #A5BE00;
$progress-status-dot-check-o: $boom-ho-red;
$progress-status-dot-check-h: $boom-ho-orange;

// Breadcrumb
$breadcrumb-primary: #EEEEEE;

// License
$license: #EEEEEE;
$license-on-color: rgba(0, 36, 57, 0.24);

// attachment
$add-attachment: #E2E5E7;

// Pagination
$pagination-navigation: #bfc1c1;
$pagination-background-color: #E8EAEB;
$pagination-button-background: #F0F1F1;
$pagination-next-previous-color: #A9AFB2;

// dialog
$dialog-track: $boom-ho-gray-lighter;
$dialog-thumb: #CAD1D8;

// Media queries
$design-breakpoint-mobile: 50rem;
$design-breakpoint-small: 50.1rem;

$media-mobile-device: "screen and (min-width: 0) and (max-width: #{$design-breakpoint-mobile})";
$media-desktop: "screen and (min-width: #{$design-breakpoint-small})";

// Buttons
$button-border-color: #D2D6D8;
$button-secondary-bg-hover: #DEDFE0;
$button-large-font-size: 1.5rem;
$button-font-size: 1.3rem;
$button-border-size: 0.2rem;

$button-small: 2.8rem;
$button-medium: 4rem;
$button-large: 5.6rem;

$button-border-radius: 0.8rem;

$button-colors-map: (
    'white': (
        base: $boom-ho-white,
        secondary: $boom-ho-gray-dark,
        borderColor: $boom-ho-gray-lighter,
        textHover: $boom-ho-black,
        borderHover: $boom-ho-gray-light,
    ),
    'variable': (
        base: $primaryColor,
        secondary: $primaryTextColor,
        borderColor: $primaryColor,
        textHover: $secondaryColor,
        baseHover: $primaryAdjustedColor,
    ),
    'primary': (
        base: $primaryColor,
        secondary: $boom-ho-white,
        borderColor: $primaryColor,
        textHover: $secondaryColor,
        baseHover: $primaryAdjustedColor,
    ),
    'primary-rgba': (
        base: $primaryColorRgba,
        secondary: $primaryColor,
        borderColor: $primaryColorRgba,
        textHover: $secondaryColor,
        baseHover: $primaryAdjustedColor,
    ),
    'primary-inverted': (
        base: $boom-ho-white,
        secondary: $boom-ho-gray-dark,
        borderColor: $boom-ho-gray-lighter,
        borderHover: $primaryColor,
        textHover: $boom-ho-white,
        baseHover:$primaryColor,
    ),
    'primary-vo': (
        base: $boom-vo-primary,
        secondary: $boom-ho-white,
        borderColor: $boom-vo-primary,
        textHover: $boom-ho-black,
        baseHover: $boom-vo-hover-background,
    ),
    'secondary': (
        base: $boom-ho-gray-light,
        secondary: $text-gray,
        borderColor: $button-border-color,
        textHover: $primaryColor,
        baseHover: $button-secondary-bg-hover,
    ),
    'grey-on-color': (
        base: transparent,
        secondary: $boom-ho-gray-dark,
        borderColor: $boom-ho-gray-dark-rgba,
        textHover: inherit,
        baseHover: $boom-ho-gray-light,
    ),
    'module-color': (
        base: var(--module-color),
        secondary: $boom-ho-white,
        borderColor: var(--module-color),
        textHover: var(--module-color),
        baseHover: $boom-ho-white,
    ),
    'breadcrumb-color': (
        base: $breadcrumb-primary,
        secondary: $text-gray,
        borderColor: $breadcrumb-primary,
        textHover: $text-gray,
        baseHover: $breadcrumb-primary,
    ),
    'breadcrumb-on-color': (
        base: rgba(0, 36, 57, 0.24),
        secondary: $boom-ho-white,
        borderColor: transparent,
        textHover: $boom-ho-white,
        baseHover: rgba(0, 36, 57, 0.24),
    ),
    'add-attachment-color': (
        base: $add-attachment,
        secondary: $text-gray,
        borderColor: $add-attachment,
        textHover: $text-gray,
        baseHover: $breadcrumb-primary,
    ),
    'sources-color': (
        base: $boom-ho-white,
        secondary: $boom-ho-secondary,
        borderColor:$boom-ho-white,
        textHover: var(--module-color),
        baseHover: $boom-ho-sources,
    ),
    'notions-color': (
        base: $boom-ho-white,
        secondary: $boom-ho-secondary,
        borderColor:$boom-ho-white,
        textHover: var(--module-color),
        baseHover: $boom-ho-sources,
    ),
    'license-color': (
        base: $license,
        secondary: $boom-ho-secondary,
        borderColor:$boom-ho-white,
        textHover: inherit,
        baseHover: $boom-ho-sources,
    ),
    'license-on-color': (
        base: $license-on-color,
        secondary: $boom-ho-secondary,
        borderColor:$boom-ho-white,
        textHover: inherit,
        baseHover: $license-on-color,
    ),
    'gray': (
        base: $boom-ho-gray-lighter,
        secondary: $text-gray,
        borderColor:$boom-ho-gray-light,
        textHover: inherit,
        baseHover: $license-on-color,
    ),
    'orange': (
        base: $boom-ho-orange,
        secondary: $boom-ho-white,
        borderColor: $boom-ho-orange,
        textHover: $boom-ho-black,
        borderHover: $boom-ho-orange,
    ),
    'orange-inverted': (
        base: $boom-ho-white,
        secondary: $boom-ho-gray,
        borderColor: $boom-ho-gray-light,
        textHover: $boom-ho-black,
        borderHover: $boom-ho-orange,
    ),
);

$icon-box-map: (
    'primary': (
        base: $boom-ho-primary-light,
        secondary: $boom-ho-primary,
    ),
    'purple': (
        base: $boom-ho-purple-light,
        secondary: $boom-ho-purple,
    ),
    'assignment': (
        base: $boom-ho-primary-rgba,
        secondary: $boom-ho-primary,
    ),
    'document': (
        base: $boom-ho-orange-rgba,
        secondary: $boom-ho-orange,
    ),
    'exam': (
        base: $boom-ho-purple-rgba,
        secondary: $boom-ho-purple,
    ),
);

$star-rating-size-map: (
    'small': (
        fontSize: 2rem,
        size: 0.8rem,
    ),
    'medium': (
        fontSize: 1.2rem,
        size: 1.2rem,
    ),
);

$status-dot-map: (
    'default': (
        base: $progress-status-dot-gray,
        secondary: $boom-ho-transparent,
    ),
    'green': (
        base: $progress-status-dot-green,
        secondary: $progress-status-dot-green,
    ),
    'red': (
        base: $progress-status-dot-red,
        secondary: $progress-status-dot-red,
    ),
    'orange': (
        base: $progress-status-dot-orange,
        secondary: $progress-status-dot-orange,
    ),
);

$status-dot-check-map: (
    'n': (
        base: $progress-status-dot-check-n,
        secondary: $progress-status-dot-check-n,
    ),
    'g': (
        base: $progress-status-dot-check-g,
        secondary: $progress-status-dot-check-g,
    ),
    'v': (
        base: $progress-status-dot-check-v,
        secondary: $progress-status-dot-check-v,
    ),
    'o': (
        base: $progress-status-dot-check-o,
        secondary: $progress-status-dot-check-o,
    ),
    'h': (
        base: $progress-status-dot-check-h,
        secondary: $progress-status-dot-check-h,
    ),
);

$button-size-map: (
    'small': (
        base: $button-small,
        fontSize: $button-large-font-size,
    ),
    'medium': (
        base: $button-medium,
        fontSize: $button-large-font-size,
    ),
    'large': (
        base: $button-large,
        fontSize: $button-large-font-size,
    ),
);

// progress circle
$progress-circle-map: (
    'primary': (
        base: $progress-circle-primary,
        secondary: $boom-ho-transparent,
    ),
    'primary-vo': (
        base: $progress-circle-vo-primary,
        secondary: $boom-ho-transparent,
    ),
    'primary-bg': (
        base: $progress-circle-primary,
        secondary: $progress-circle-light-primary,
    ),
    'dark': (
        base: $progress-circle-primary,
        secondary: $boom-ho-transparent,
    ),
    'dark-bg': (
        base: $progress-circle-primary,
        secondary: $progress-circle-light-primary,
    ),
    'orange': (
        base: $progress-circle-orange,
        secondary: $boom-ho-transparent,
    ),
    'orange-bg': (
        base: $progress-circle-orange,
        secondary: $progress-circle-light-orange,
    ),
    'resit': (
        base: $progress-status-dot-check-h,
        secondary: $progress-status-dot-check-h,
    ),
    'red': (
        base: $progress-circle-red,
        secondary: $progress-circle-light-red,
    ),
    'blue': (
        base: $boom-ho-primary,
        secondary: $progress-circle-light-blue,
    ),
    'purple': (
        base: $progress-circle-purple,
        secondary: $boom-ho-transparent,
    ),
    'green': (
        base: $progress-circle-green,
        secondary: $boom-ho-transparent,
    ),
    'purple-bg': (
        base: $progress-circle-purple,
        secondary: $progress-circle-light-purple,
    ),
    'no-value': (
        base: $boom-ho-gray-dark,
        secondary: $progress-circle-no-value-bg,
    ),
    'no-value-dark-mode': (
        base: $boom-ho-gray-dark,
        secondary: $progress-circle-no-value-dark-mode-bg,
    ),
);

$progress-circle-size-map: (
    'default': (
        base: $progress-circle-default-size,
        font-size: $progress-circle-default-font-size,
    ),
    'small': (
        base: $progress-circle-small-size,
        font-size: $progress-circle-small-font-size,
    ),
    'large': (
        base: $progress-circle-large-size,
        font-size: $progress-circle-default-font-size,
    ),
);

// Border radius
$border-radii: (
    'xxs': 0.8rem,
    'xs': 1.2rem,
    'xs-sm': 2.0rem,
    'sm': 3.5rem,
    'md': 4.0rem,
    'lg': 7.5rem,
);

// Z-indexes
$z-index: (
    'navigation': 3,
);

// vo-colors
$boom-vo-secondary: #E2E5E7;
$boom-vo-gray: #B5BEC4;
$boom-vo-footer-text: #718490;

$boom-vo-drillster-background-color: rgba(8, 201, 202, 0.05);

// include-media breakpoints
$breakpoints: (phone: 559px, tablet: 559px,  desktop: 959px);
