@import '~assets/scss/vendors/redactor/_scss/mixins';
@import '~assets/scss/vendors/redactor/_scss/animation';
@import '~assets/scss/vendors/redactor/_scss/icons';
@import '~assets/scss/vendors/redactor/_scss/base';
@import '~assets/scss/vendors/redactor/_scss/source';
@import '~assets/scss/vendors/redactor/_scss/placeholder';
@import '~assets/scss/vendors/redactor/_scss/statusbar';
@import '~assets/scss/vendors/redactor/_scss/toolbar';
@import '~assets/scss/vendors/redactor/_scss/toolbar-air';
@import '~assets/scss/vendors/redactor/_scss/accessibility';
@import '~assets/scss/vendors/redactor/_scss/context';
@import '~assets/scss/vendors/redactor/_scss/dropdown';
@import '~assets/scss/vendors/redactor/_scss/modal';
@import '~assets/scss/vendors/redactor/_scss/styles';
@import '~assets/scss/vendors/redactor/_scss/upload';
@import '~assets/scss/vendors/redactor/_scss/structure';
@import '~assets/scss/vendors/redactor/_scss/progress';
@import '~assets/scss/vendors/redactor/_scss/fullscreen';

$textColor: var(--text-color, $boom-ho-gray);

// Override CSS
.redactor-box {
    min-height: $redactor-box-min-height;

    &.redactor-focus.redactor-styles-on,
    &.redactor-toolbar-on {
        border-color: $redactor-toolbar-border-color!important;
        border-radius: 0.8rem;
    }

    .redactor-styles {
        p {
            margin-bottom: 0;
        }
    }

    .redactor-toolbar-wrapper {
        .redactor-toolbar {
            position: relative;
            padding: 0;
            height: $redactor-toolbar-height;
            border-radius: $redactor-toolbar-border-radius;
            z-index: 1;

            a {
                font-size: 2.5rem;
                padding: 0.9rem 1.4rem 0.6rem 1.4rem;
                box-shadow: none;
                line-height: $redactor-toolbar-line-height;
                margin: 0;
                border-radius: 0;

                &:first-child {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    border-top-left-radius: $redactor-toolbar-border-radius;
                }

                &:hover {
                    background-color: $boom-ho-gray;

                    i {
                        color: $boom-ho-white;
                    }

                }

                i {
                    color: $boom-ho-gray;

                    &:before {
                        color: inherit;
                    }
                }

                &.re-toggle-toolbar {
                    float: right;
                    border-right: 0;
                    height: $redactor-re-toggle-toolbar;
                    border-radius: $redactor-toolbar-border-radius;
                }
            }

        }
    }

    &.redactor-styles-on .redactor-placeholder.redactor-placeholder  {
        p {
            margin-left: 0;
        }
    }

    &.redactor-box--toolbar-collapsed {
        .redactor-toolbar-wrapper {
            .redactor-toolbar {
                border: 0;
                position: absolute;
                right: 0;

                a {
                    display: none;
                    height: $redactor-toolbar-collapsed;

                    &.re-toggle-toolbar {
                        display: block;
                        height: $redactor-toolbar-height;
                    }
                }
            }
        }
        .redactor-styles {
            padding-right: $redactor-toolbar--collapsed-padding-right;
            overflow: hidden;
        }

    }

}

.redactor-button-active {
    // we need to important these ones, in the original file (css) we can't preform checks
    background-color: inherit !important;
    i {
        color: $textColor !important;
    }
}
