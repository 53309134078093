.mat-button {
    border-radius: 1.2rem!important;

    &--group-select {

        .mat-button-wrapper {
            display: flex;
        }
    }
}

.mat-icon-button {
    border: none !important;
}
