// Vendor overrides
@import '~assets/scss/vendors';

// General styling
@import '~assets/scss/mixins/typology';
@import '~assets/scss/mixins/container';
@import '~assets/scss/mixins/fonts';
@import '~assets/scss/mixins/flex';
@import '~assets/scss/mixins/icon';

// Components and documents
@import '~assets/scss/components';
@import '~assets/scss/documents';
@import '~assets/scss/variables';

html {
    font-size: 62.5%;

    // Disable tap blinking when on mobile
    -webkit-tap-highlight-color: transparent;
    scrollbar-color: $boom-ho-secondary $boom-ho-gray-lighter;
    scrollbar-width: thin;
}

html, body {
    height: 100%;
}

body {
    @include poppins-regular;
    color: $boom-ho-secondary;
    padding: 0;
    margin: 0;
}

.content-shield {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

// Global typography
@include heading-h1;
@include heading-h2;
@include heading-h3;
@include span;

// Page container
@include container;
