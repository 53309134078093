@import '~assets/scss/variables';

.custom-dialog-container {
    .mat-dialog-container {
        padding: 0;
        border-radius: 1.2rem;

        &--loading {
            .mat-dialog-container {
                overflow: hidden;
            }
        }
    }

    &__side-panel {
        height: 100%;
        max-height: 100%;

        .mat-dialog-container {
            background-color: $boom-ho-gray-lighter;
            padding: 0;
            border-radius: 1.2rem 0 0 1.2rem;
        }
    }

    &__lightbox {
        .mat-dialog-container {
            background: transparent;
            box-shadow: none;
        }
    }

    &__overview-dialog {
        max-width: 100%;
        height: 100%;
        max-height: 88vh;
        min-height: 20vh;

        .mat-dialog-container {
            padding: 0;
            border-radius: 1.2rem;
        }
    }
}

.mat-dialog-container {
    &--loading {
        .mat-dialog-container {
            overflow: hidden;
        }
    }
}
