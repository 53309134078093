@import '~assets/scss/variables';
@import '~assets/scss/mixins/fonts';
@import 'include-media';

.mat-accordion {
    .mat-expansion-panel-header {
        height: 8.8rem;

        &:hover {
            background-color: $boom-ho-gray-lighter!important;

            @include media(">phone") {
                background-color: $boom-ho-gray-lightest!important;
            }
        }

        &-title {
            align-items: center;
        }
    }

    .mat-expansion-panel {
        background-color: $boom-ho-gray-lighter;
        box-shadow: unset;
        border-radius: 0.8rem!important;
        margin-bottom: 1rem;

        @include media(">phone") {
            background-color: $boom-ho-gray-lightest;
        }
    }

    .mat-expansion-indicator {
        margin-top: -1rem;

        &::after {
            padding: 0.5rem;
        }
    }
}

