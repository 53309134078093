@import '~assets/scss/mixins/fonts';
@import '~assets/scss/variables';

$termColor: var(--term-color, $boom-ho-white);
$termBackgroundColor: var(--term-background-color, $boom-ho-black);

&-term {
    @include poppins-semi-bold(1.6rem, 2.5rem);
    color: $termColor;
    cursor: pointer;

    &-index,
    &-definition {
        display: none;
    }

    &-tool-tip {
        @include poppins-regular(1.4rem);
        background-color: $boom-ho-secondary;
    }
}
