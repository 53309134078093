@import '~assets/scss/variables';

@mixin mark {
    .mark {
        background-color: transparent;
        color: $primaryColor;

        @content;
    }
}
