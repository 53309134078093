@import '~assets/scss/variables';
@import '~assets/scss/mixins/typology';

.document-hotspot-block {
    margin-bottom: 2em;

    .disable-select {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .document__question-answer, .document__question-handed-in, .document__question-initial-answer {
        padding: 0;
    }

    .document__question-handed-in, .document__question-initial-answer {
        .hotspot-image-container .hotspot-image {
            border: 0 none!important;
        }
    }

    .hotspot-image-container {
        position: relative;

        .drag-hide {
            transition: 0.01s;
            transform: translateX(-9999px);
        }

        .hotspot-image {
            border: 0.1rem solid $boom-ho-secondary;
            border-radius: 0.3rem;
        }

        .hotspot-delete-markers {
            position: absolute;
            right: -0.2rem;
            top: 0;
            padding: 1rem 1rem 0.5rem;
            border: 0.1rem solid $boom-ho-secondary;
            border-radius: 0 0.3rem;
            background-color: $boom-ho-white;
            cursor: pointer;

            &:hover {
                background-color: $boom-ho-secondary;
                color: $boom-ho-white;
            }

            i.icon {
                font-size: 2rem
            }
        }

        .hotspot-marker-container {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;

            &--disabled {
                cursor: not-allowed;
                pointer-events: none;
            }
        }
    }

    app-marker-component {
        position: absolute;
        width: 100%;
        height: 100%;
    }
    .document-content-media__container {
        width: 100%;
        height: 100%;
    }

    &.hotspot-show-correct-answer {
        .hotspot-correct-answer-block {
            display: block;
        }
    }

    .hotspot-correct-answer-block {

        img.image-blurred {
            filter: blur(0.8rem);
        }
    }

    &__initial {
        border: 0.1rem solid $boom-ho-gray;
        position: relative;

        &:before {
            @include label-style;
            background-color: $boom-ho-gray;
        }
    }

    &__reviewed {
        border: 0.1rem solid $boom-vo-primary;
        position: relative;

        &:before {
            @include label-style;
            background-color: $boom-vo-primary;
        }
    }


    &__correct {
        border: 0.1rem solid $boom-ho-orange;
        position: relative;

        &:before {
            @include label-style;
            background-color: $boom-ho-orange;
        }
    }
}

.hotspot-marker {
    background-color: $boom-ho-primary;
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 1rem;
    transform: translate(-50%, -50%);
}

.clip-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .hotspot-clip-element {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 1;
        background-size: 100% 100%;
    }
}

.clip-outline-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .clip-outline {
        position: absolute;
        border: 0.2rem solid $boom-ho-orange;
    }
}

