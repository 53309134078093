@import '@angular/flex-layout/mq';
@import '~assets/scss/variables';
@import '~assets/scss/mixins/fonts';
@import '~assets/scss/mixins/typology';
@import '~assets/scss/documents/variables';

@mixin document-numbered-heading() {
    &:before {
        content: attr(data-number);
        margin-right: 1rem;
    }

    // @deprecated fxFlex will be removed in the future
    @include layout-bp(gt-xs) {
        position: relative;

        &:before {
            position: absolute;
            content: attr(data-number);
            margin-right: 0;
            margin-left: -$document-section-left-margin-lg;
        }
    }
}

@mixin document-heading-h2 {
    @include poppins-bold(3.2rem, 4.8rem);
    margin: 4rem 0 0.8rem 0;

    &[data-number] {
        @include document-numbered-heading;
    }
}

@mixin document-heading-h3 {
    @include poppins-bold(2.4rem, 3.6rem);
    margin: 2.4rem 0 0.2rem 0;

    &[data-number] {
        @include document-numbered-heading;
    }
}

@include paragraph {
    @include poppins-regular;
    line-height: 3.2rem;
    margin: 0;
}

@include heading-h2 {
    &--document {
        @include document-heading-h2;
    }
}

@include heading-h3 {
    &--document {
        @include document-heading-h3;
    }
}

.heading-h4 {
    &--document {
        @include poppins-bold(2rem, 3.6rem);
        margin: 2.4rem 0 -1rem 0;
    }
}

.heading-h5 {
    &--document {
        @include poppins-bold(1.6rem, 3.6rem);
        margin: 2.4rem 0 -1rem 0;
    }
}

.document-paragraph:has(+ ul) {
    margin-bottom: 0.5rem;
}

.document-paragraph:has(+ ol) {
    margin-bottom: 0.5rem;
}
