@import '~assets/scss/variables';
@import '~assets/scss/font';

$contentMarkColor: var(--content-mark-color, $boom-ho-white);

.mat-menu-panel {
    overflow-x: hidden !important; // overrulling mat-menu-panel's overflow-x: auto
    min-height: auto !important;

    &--ebook,
    &--single-select,
    &--group-menu,
    &--module-menu,
    &--sources,
    &--notions,
    &--manage-licenses,
    &--books-sort,
    &--messages {
        max-width: unset !important;
        border-radius: 1.2rem !important;
        max-height: 40rem !important;
    }

    &--manage-licenses,
    &--books-sort {
        min-height: 5.4rem !important;
    }

    &--page-select {
        border-radius: 1.2rem !important;
        max-height: 24rem !important;
    }

    &--multi-select {
        border-radius: 1.2rem !important;
        max-height: 40rem !important;
        overflow-x: hidden;
    }

    .mat-menu-item {
        padding: 0;
        box-shadow: inset 0 -0.1rem 0 rgba(0, 36, 57, 0.16);
        flex-shrink: 0;

        &--unset-white-space {
            white-space: unset;
            text-overflow: unset;
            flex-direction: column;
            box-sizing: border-box;
            display: flex;
        }
    }

    .mat-menu-content:not(:empty) {
        padding: 0;
    }

    &--single-select,
    &--group-menu,
    &--module-menu {
        .mat-menu-item {
            padding: 0 1.2rem;
            box-shadow: none;
            height: 4rem;
            line-height: 4rem;
            flex-shrink: 0;

            &:not(:last-child) {
                margin-bottom: 0.8rem;
            }
        }

        .mat-menu-content:not(:empty) {
            padding: 1.2rem 0;
        }
    }

    &--multi-select {
        .mat-menu-item {
            padding: 0 0 0 0;
            box-shadow: none;
            height: 4rem;
            flex-shrink: 0;
            line-height: 4rem;

            &:not(:last-child) {
                margin-bottom: 0.8rem;
            }
        }

        .mat-menu-content:not(:empty) {
            padding: 1.2rem 0;
        }
    }

    &--user-select {
        // Not much of a decent solution, but mat-menu adds height as style and we can't override that (no settings available either)
        max-height: 5.2rem * 5 !important;
    }

    &.mat-menu-panel--hint {
        min-width: 57rem;
        border-radius: 1.2rem;
        margin-top: 0.5rem;
        padding: 1.6rem;

        @media #{$media-mobile-device} {
            min-width: 30rem;
        }

        .document-hint {
            &__header {
                display: flex;
                align-items: center;
            }

            &__title {
                @include montserrat-alternates-bold(1.6rem, 3.2rem);
                margin-left: 2.4rem;
            }

            &__icon {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $primaryColorRgba;
                font-size: 2rem;
                height: 3.6rem;
                width: 3.6rem;
                border-radius: 0.8rem;
                line-height: 0;
            }

            &__container {
                flex-direction: row;
                box-sizing: border-box;
                display: flex;
            }

            &__content-divider {
                flex: 1 1 5rem;
                box-sizing: border-box;
                max-width: 5rem;
                min-width: 5rem;
                margin-top: 0.8rem;
            }

            &__divider {
                height: 100%;
                padding-left: 1.5rem;
                width: 0.2rem;
                border-right: 0.2rem solid $boom-ho-black;
            }

            &__content {
                margin: 0 1rem;
                line-height: 3.2rem;
            }
        }
    }
}
