@import '~assets/scss/variables';

.document-hint {
    display: flex;
    width: 3.6rem;
    height: 3.6rem;
    background: $boom-ho-white;
    box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.12);
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    cursor: pointer;

    .icon {
        font-size: 2rem;
    }
}

.document-feedback-neutral {
    position: absolute;
    top: 0;
    right: 0;
}

