.redactor-source,
.redactor-source:hover,
.redactor-source:focus {
    text-align: left;
	box-sizing: border-box;
    font-family: $monospace-font-family;
    width: 100%;
    display: block;
	margin: 0;
	border: none;
	box-shadow: none;
	border-radius: 0;
	background: #252525;
	color: #ccc;
	font-size: 15px;
	outline: none;
	padding: 10px 18px 20px 18px;
	line-height: 1.5;
	resize: vertical;
}
.redactor-box[dir="rtl"] {
    .redactor-source {
        direction: ltr;
    }
}