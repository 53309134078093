@import '~assets/scss/variables';
@import '~assets/scss/mixins/fonts';

$primaryColor: var(--primary-color, $boom-ho-gray-lighters);

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: $primaryColor !important;
}

.mat-checkbox {
    &--exam-overview {
        display: flex!important;
        align-items: center;

        &.mat-checkbox-checked {
            .mat-checkbox-background {
                background-color: $select-box-background!important;
            }

            .mat-checkbox-checkmark {
                width: 1.6rem;
                top: 0.2rem;
                left: 0.2rem;
            }
        }

        .mat-checkbox-frame {
            border-radius: 100%;
        }

        .mat-checkbox-inner-container {
            width: 2rem;
            height: 2rem;
        }

        .mat-checkbox-background {
            border-radius: 100%;
        }

        .mat-checkbox-checkmark-path {
            stroke: $select-box-checked!important;
        }
    }
}

