@import '~assets/scss/variables';
@import '~assets/scss/mixins/border';
@import '~assets/scss/mixins/pseudo';

@mixin button-color($primary-color, $secondary-color, $border-color: $primary-color, $text-hover, $primary-hover, $border-hover) {
    border-color: $border-color;
    background-color: $primary-color;
    color: $secondary-color;

    &:hover:not(:disabled) {
        color: $text-hover;
        background-color: $primary-color;

        @if ($primary-hover) {
            background-color: $primary-hover;
        }

        @if ($border-hover) {
            border-color: $border-hover;
        }
    }
}

@mixin button-disabled() {
    &:disabled,
    &--disabled,
    &--disabled:hover {
        background-color: $boom-ho-gray-lighter;
        border-color: $boom-ho-gray-light;
        color: $text-gray;
        pointer-events: none;
    }
}

@mixin button-border($rgba, $size: 0.2rem, $style: solid) {
    border: $size $style $rgba;
}

@mixin button-size($value) {
    $size: map-get($value, base);
    $font-size: map-get($value, fontSize);

    line-height: $size - (2 * $button-border-size);
    font-size: $font-size;
}

@mixin button-colors($value) {
    $primary-color: map-get($value, base);
    $secondary-color: map-get($value, secondary);
    $border-color: map-get($value, borderColor);
    $text-hover: map-get($value, textHover);
    $primary-hover: map-get($value, baseHover);
    $border-hover: map-get($value, borderHover);

    @include button-color($primary-color, $secondary-color, $border-color, $text-hover, $primary-hover, $border-hover);
}

@mixin button {
    .btn {
        @include poppins-semi-bold;
        @include border-radius($button-border-radius);
        @include button-size(map-get($button-size-map, 'small'));
        align-items: center;
        text-align: center;
        color: $text-gray;
        border: $button-border-size solid rgba(0, 36, 57, 0.08);
        box-sizing: border-box;
        padding: 0 1.6rem;
        cursor: pointer;

        &:focus {
            outline: none;
        }

        @each $name, $value in $button-colors-map {
            &--#{$name} {
                @include button-colors($value);
            }
        }

        @each $name, $value in $button-size-map {
            &--#{$name} {
                @include button-size($value);
            }
        }

        .icon {
            margin-right: 1rem;
            font-size: 1.8rem;
        }

        // Modifiers
        @each $name, $value in $color-map {
            &--color-#{$name} {
                color: $value;
            }
        }

        @each $name, $value in $color-map {
            &--color-bg-#{$name} {
                background-color: $value;
            }
        }

        &--hidden {
            display: none;
        }

        &--center {
            display: flex;
            justify-content: center;
            min-width: 14rem;
        }

        &--icon {
            color: $boom-ho-gray;
            border: 2px solid rgba(0, 36, 57, 0.08);
            background-color: transparent;
            font-size: 1.8rem;
            padding: 0;

            .icon {
                margin: auto;
            }
        }

        &--auto-margin-top-bottom {
            margin: auto 0;
        }

        &--opacity:hover {
            opacity: 0.8;
        }

        &--color-inherit {
            color: inherit;
        }

        &--module {
            @include border-with-opacity($primaryTextColor, $button-border-size, $button-border-radius);
            background-color: transparent;
            color: $primaryTextColor;
            border: none; // Disable default button border...

            &:hover {
                background-color: $boom-ho-white;
                color: $primaryColor;
            }
        }

        @include button-disabled();

        @content;
    }
}
