@use 'sass:math';
@import '~assets/scss/mixins/border';

@mixin corner-top-left-inversed($radius) {
    position: relative;

    &:before {
        @include border-radius-inverse($radius);
        transform: rotate(90deg);
        z-index: 2;
        background: inherit;
        content: '';
        position: absolute;
        top: -$radius;
        width: $radius;
        height: $radius;
        left: 0;
    }
}

@mixin corner-bottom-left-inversed($radius) {
    position: relative;

    &:before {
        @include border-radius-inverse($radius);
        transform: rotate(0);
        z-index: 2;
        background: inherit;
        content: '';
        position: absolute;
        top: unset;
        bottom: 0;
        width: $radius;
        height: $radius;
        left: -$radius;
    }
}

@mixin border-with-opacity($color, $size, $radius, $opacity: 0.32, $hoverOpacity: 1) {
    position: relative;

    &:before {
        @include border-radius($radius);
        border: $size solid $color;
        position: absolute;
        opacity: $opacity;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: '';
    }

    &:hover:not(:disabled):before {
        opacity: $hoverOpacity;
        border-color: transparent;
    }
}

@mixin before-circle($size, $backgroundColor) {
    &::before {
        @include border-radius(math.div($size, 2));
        content: '';
        display: inline-block;
        background-color: $backgroundColor;
        height: $size;
        min-height: $size;
        width: $size;
        min-width: $size;

        @content;
    }
}

@mixin before-hide-circle($size, $backgroundColor) {
    &::before {
        content: '';
        display: none;
        background-color: green;

        @content;
    }
}

@mixin before-triangle($size, $backgroundColor) {
    &::before {
        @include border-radius(math.div($size, 2));
        content: '';
        display: inline-block;
        border-top: $size solid transparent;
        border-bottom: $size solid transparent;
        border-left: $size solid $backgroundColor;
        height: 0;
        width: 0;

        @content;
    }
}
