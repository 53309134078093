@mixin border-radius-inverse($size) {
    -webkit-mask-image: radial-gradient(circle $size at 0 0, transparent 0, transparent $size, black $size);
    mask-image: radial-gradient(circle $size at 0 0, transparent 0, transparent $size, black $size);
}

@mixin border-radius($radius) {
    border-radius: $radius;
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
}

@mixin border-radius-left($radius) {
    @include border-radius($radius 0 0 $radius);
}

@mixin border-radius-top($radius) {
    @include border-radius($radius $radius 0 0);
}

@mixin border-radius-right($radius) {
    @include border-radius(0 $radius $radius 0);
}

@mixin border-radius-bottom($radius) {
    @include border-radius(0 0 $radius $radius);
}

@mixin border-radius-top-left($radius) {
    @include border-radius($radius 0 0 0);
}

@mixin border-radius-top-right($radius) {
    @include border-radius(0 $radius 0 0);
}

@mixin border-radius-bottom-right($radius) {
    @include border-radius(0 0 $radius 0);
}

@mixin border-radius-bottom-left($radius) {
    @include border-radius(0 0 0 $radius);
}
