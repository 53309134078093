@import '~assets/scss/variables';
@import '~assets/scss/mixins/border';

.icon {
    margin: auto;

    &--no-margin {
        margin: initial;
    }

    &--tile {
        @include border-radius(0.5rem);
        height: 3.2rem;
        width: 3.2rem;
    }
}

.icon-box {
    @mixin icon-box-color($base-color, $secondary-color) {
        background-color: $base-color;
        color: $secondary-color;
    }

    @each $name, $value in $icon-box-map {
        $base-color: map-get($value, base);
        $secondary-color: map-get($value, secondary);

        &--#{$name} {
            @include icon-box-color($base-color, $secondary-color);
        }
    }

    &--border-radius {
        border-radius: 1.2rem;
    }
}

@mixin icon-boxed-display($radius, $backgroundColor, $color) {
    @include border-radius($radius);
    background-color: $backgroundColor;
    color: $color;
}
