@import '~assets/scss/variables';
@import '~assets/scss/mixins/fonts';
@import '~assets/scss/mixins/typology';
@import '~assets/scss/mixins/pseudo';
$contentMarkColor: var(--content-mark-color, $boom-ho-white);
$iconBackgroundColor: var(--icon-background-color, $boom-ho-gray-lighter);
$textColor: var(--text-color, $boom-ho-gray);
$contentTextColor: var(--content-text-color, $boom-ho-primary);
$contentMarkColor: var(--content-mark-color, $boom-ho-white);

.document {
    &-content {
        width: 100%;

        &-mark {
            margin: 2rem 0;
            border-radius: 0.75rem;
            background-color: $contentMarkColor;

            .document-resourcelink,
            .document__resource-link {
                @include poppins-bold;
                color: $textColor;
                text-decoration: none;
                font-style: normal;
            }

            .document-source-reference {
                .document-paragraph {
                    @include poppins-bold;
                    color: $textColor;
                    text-decoration: none;
                }
            }

            &__content {
                display: flex;
                margin-right: 0.6rem;

                &--not-filled:empty {
                    display: none;
                }

                .heading-h4--document {
                    font-size: 2.0rem !important;
                    display: flex;
                    color: $textColor;
                    margin-top: 3rem;
                    @include poppins-bold(2.0rem, 3.6rem);
                }

                .heading-h3--document {
                    font-size: 2.4rem !important;
                    display: flex;
                    color: $textColor;
                    margin-top: 3rem;
                    @include poppins-bold(2.4rem);
                    line-height: 3.6rem!important;
                }

                &-border {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 5.6rem;
                    width: 5.6rem;
                    border-radius: 0.875rem;
                    background-color: $contentMarkColor;
                    line-height: 0;

                }

                &-border-icon {
                    height: 3.5rem;
                    width: 3.5rem;
                    margin: 3rem 2rem 0 0;
                    border-radius: 100%;
                    background-color: $contentMarkColor;
                    cursor: pointer;
                    transform: rotate(90deg);

                    .icon--next:before {
                        color: $textColor;
                        font-size: 2.4rem;
                    }

                    &-up {
                        transform: rotate(270deg);
                    }

                    &-position {
                        height: 100%;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }

                &-icon {
                    padding: 2rem;
                    font-size: 2.4rem;
                    color: $textColor;
                }

                &-icon-icon {
                    font-size: 2.4rem;
                    color: $textColor;

                    &-wrapper-icon {
                        padding: 1.6rem;
                    }
                }

                &-positioner {
                    width: 1.5rem;
                }

                @include heading-h3-content-mark-case($textColor, 2.4rem);

                @include heading-h4-content-mark-case($textColor, 2rem);

                @include heading-h5-content-mark-case($textColor, 1.6rem);

               a {
                    @include poppins-semi-bold;
                    text-decoration: none;
                }

                &-divider {
                    position: absolute;
                    height: -webkit-fill-available;
                    margin-left: -0.5rem;
                    margin-bottom: 3rem;
                    height: calc(100% - 3rem); // safari
                    width: 0.2rem;
                    border-right: 0.2rem solid $iconBackgroundColor;
                }

                &-citation {
                    font-style: italic;

                    .document-source-reference > * {
                        font-style: normal;
                    }

                    a {
                        @include poppins-semi-bold;
                        color: $textColor;
                        text-decoration: none;
                        font-style: normal;
                    }
                }

                &-bottom {
                    display: block !important;
                    position: relative;
                }

                &-indent {
                    display: block !important;
                    position: relative;
                    padding-left: 5rem;

                    &-text {
                        margin-top: -2.4rem !important;
                    }
                }

                &-no-title {
                    margin-top: -1.5rem;
                }

                &-content {
                    margin: 0 2rem 0 5rem;
                    color: $contentTextColor;

                    &-wrapper {
                        margin: 1.4rem 0;
                        width: 100%
                    }
                }

                &-wrapper {
                    margin-top: 1.5rem;
                }

                .heading-h2, .heading-h3, .heading-h4 {
                    text-align: unset;
                }
            }

            .document-table-responsive {
                margin-top: 2rem;

                .table--document-table-financial {
                    border: 0.1rem solid #A6B2B8;
                }
            }
        }
    }

    &-citation {
        .heading-h3 {
            @include poppins-bold(2.4rem);
        }
    }

    &__resource-link {
        @include poppins-bold;
        text-decoration: none;
        color: inherit;
        font-style: normal;
    }

    &-taskplan {
        .document-steplist {
            counter-reset: step-counter;
            margin-left: -5rem;
        }

        .document-steplistitem {
            display: flex;
            align-items: baseline;
            color: $textColor;
            @include poppins-medium();
            gap: 3.5rem;
        }

        .document-content-mark__content-content {
             margin-top: 2rem;
        }

        .document-steplistitem:before {
            display: flex;
            justify-content: center;
            border-radius: 0.8rem;
            counter-increment: step-counter;
            content: counter(step-counter) ".";
            margin-left: -2rem;
            top: 0;
            background-color: $iconBackgroundColor;
            width: 3.2rem;
            min-width: 3.2rem;
            box-shadow: inset 0 0 2rem 2rem $iconBackgroundColor;
            z-index: 2;
        }
    }

    &-inline-verwijzing {
        .heading-h2--document:first-of-type {
            display: none;
        }
    }
}


