@import '~assets/scss/variables';
@import '~assets/scss/mixins/fonts';

.mat-tooltip {
    &--progress {
        @include poppins-regular(1.3rem, 2rem);
        background-color: $boom-ho-secondary;
        color: $boom-ho-white;
    }

    &--sidebar {
        @include poppins-regular(1.3rem, 2rem);
        background-color: $boom-ho-secondary;
        margin: 1rem 1.4rem -1rem 1.4rem !important;
        color: $boom-ho-white;
    }
}
