.document--in-overlay {
    .document__section[data-level="1"] {
        margin-left: 0;
        max-width: 100%;
    }

    textarea {
        cursor: pointer;

        &:focus {
            outline: none;
        }
    }
}
