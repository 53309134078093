@import '~assets/scss/variables';

.drag-and-drop {
    @mixin pseudo-block($z-index) {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: $z-index;
    }

    &__draggable {
        position: relative;
        z-index: 1;

        &--enabled::after {
            @include pseudo-block(2);
            cursor: move;
        }

        &--dragover::before {
            @include pseudo-block(3);
            background-color: $boom-ho-gray-dark-rgba;
        }
    }
}
