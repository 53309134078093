@import '~assets/scss/variables';

.mat {
    &-paginator {
        background-color: transparent;
        display: grid;

        &-container {
            align-items: center;
            justify-content: center;
            margin-left: 7rem;
        }

        &-page {
            &-size {
                display: none !important;
            }
        }

        &-range {
            &-label {
                display: none;
            }
        }

        &-navigation {
            &-first {
                visibility: hidden !important;
            }

            &-last {
                visibility: hidden !important;
            }

            &-previous {
                background-color: $pagination-navigation;
                cursor: pointer !important;
            }

            &-next {
                background-color: $pagination-navigation;
                cursor: pointer !important;
            }
        }

        &-icon {
            &-button {
                display: flex !important;
                @include poppins-semi-bold(1.5rem, 2.0rem);
                border: 0.1rem solid $pagination-navigation !important;
                margin: 0 0.8rem !important;
                align-items: center !important;
                justify-content: center;
            }
        }

        &-button {
            &-wrapper > * {
                color: $boom-ho-secondary;
            }
        }
    }
}

.btn_custom-paging-container {
    display: flex;

    .mat-icon-button {
        cursor: pointer !important;
        margin: 0 0.8rem;
    }
}
