@import '~assets/scss/variables';

.document-sortable-block {
    $root-class: &;

    $textColor: var(--text-color, $boom-ho-secondary);

    &--image {
        max-width: 31rem;
        border-color: $boom-ho-gray-light;
    }

    &__item {
        display: flex;
        align-items: center;
        position: relative;
        @include poppins-regular(1.6rem, 2.2rem);
        padding: 1.25rem 5.1rem 1.25rem 1.25rem;
        border: 0.1rem solid $boom-ho-matching-border-color;
        filter: drop-shadow(0 0.1rem 0.2rem rgba(0, 0, 0, 0.12));
        background-color: $boom-ho-white;
        margin-bottom: 0.8rem;
        border-radius: 0.4rem;
        flex: 1 1 100%;

        &:before {
            font-family: 'boomho';
            content: "\eb14";
            font-size: 1.4rem;
            color: $boom-ho-matching-drag-handle-color;
            display: flex;
            align-items: center;
            position: absolute;
            right: 1.6rem;
            height: 100%;
        }

        &:hover:before {
            color: $textColor;
        }

        .document-content-media__container img {
            border-radius: 0;
        }

        &--image {
            padding: 1rem;
            border-bottom-color: $boom-ho-gray-light !important;

            img {
                border: 0.1rem solid $boom-ho-secondary;
                border-radius: 6rem;
                max-width: 100%;
            }

            &:hover {
                background: $boom-ho-gray-light;
            }
        }

        // Due to vendor limitations, we'll have to modify the preview version this way
        &.cdk-drag-preview {
            border: 0.1rem solid $boom-ho-secondary !important;
        }

        .document-content-media__container {
            margin: 0;
        }
    }

    &__number {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 4.8rem;
        height: 100%;
        color: $textColor;
        margin-left: -1.25rem;

        &:after {
            content: '';
            position: absolute;
            border-right: 0.1rem solid $boom-ho-gray-lighters;
            height: 100%;
            left: 4.5rem;
        }

        &:nth-child(2):after {
            left: 9rem;
        }
    }

    &__label {
        margin-left: 1.2rem;
    }

    &--zoom-image {
        .document-sortable-block__label {
            display: flex;
            flex: 1 1 100%;
            justify-content: center;
        }
    }

    &--disabled {
        #{$root-class}__item--image {
            background-color: $boom-ho-primary-lighter;

            img {
                border-color: $boom-ho-primary;
            }

            &:hover {
                background: transparent;
            }
        }
    }

    &--correct-answers {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-color: $boom-ho-orange;
        position: relative;

        &:before {
            @include label-style;
            background-color: $boom-ho-orange;
        }

        &#{$root-class}--image {
            background-color: $boom-ho-primary-lighter;
            border-color: transparent;
        }

        .document-sortable-block__item {
            padding: 1.25rem 5.1rem 1.25rem 1.25rem;
            border: 0.1rem solid $boom-ho-orange;

            &--image {
                border-bottom-color: transparent !important;

                img {
                    border-color: $boom-ho-orange;
                }

                &:hover {
                    background: transparent;
                }
            }
        }

        &-image {
            .document-sortable-block__item {
                display: flex;
                flex: 1 1 100%;
                justify-content: center;
                padding: 1.25rem 4.1rem 1.25rem 1.25rem;
            }
        }
    }

    &--initial-answers {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-color: $boom-ho-gray;
        position: relative;

        &:before {
            @include label-style;
            background-color: $boom-ho-gray;
        }

        &#{$root-class}--image {
            background-color: $boom-ho-gray-light;
            border-color: transparent;
        }

        .document-sortable-block__item {
            padding: 1.25rem 5.1rem 1.25rem 1.25rem;
            border: 0.1rem solid $boom-ho-gray;

            &--image {
                border-bottom-color: transparent !important;

                img {
                    border-color: $boom-ho-gray;
                }

                &:hover {
                    background: transparent;
                }
            }
        }
    }

    &--reviewed-answers {
        position: relative;

        &:before {
            @include label-style;
            background-color: $boom-vo-primary;
        }

        .document-sortable-block__item {
            padding: 1.25rem 5.1rem 1.25rem 1.25rem;
            border: 0.1rem solid $boom-vo-primary;

            &--image {
                border-bottom-color: transparent !important;

                img {
                    border-color: $boom-vo-primary;
                }

                &:hover {
                    background: transparent;
                }
            }
        }

    }
}
