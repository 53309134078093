@import '~assets/scss/variables';

.document-feedback {
    display: flex;
    float: right;
    width: 3.6rem;
    height: 3.6rem;
    background: $boom-ho-white;
    box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.12);
    border-radius: 0.5rem;
    cursor: pointer;

    .icon {
        font-size: 2rem;
    }
}

