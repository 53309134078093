@import '~assets/scss/variables';
@import '~assets/scss/mixins/border';
@import '~assets/scss/mixins/box-shadow';

.mat-radio-group {
    .mat-error {
        margin: 0.5rem 0 0 1.25rem;
    }
}

.mat-radio-button {
    &.mat-radio-button--card {
        @include box-shadow($boom-box-shadow);
        @include border-radius(1.2rem);
        background-color: $boom-ho-white;
        display: flex;
        height: 5.5rem;
        flex: 1;

        > .mat-radio-label {
            flex: 1;
            margin: 0 2.6rem;

            .mat-radio-container {
                .mat-radio-outer-circle {
                    border-color: $primaryColor;
                }

                .mat-radio-inner-circle {
                    background-color: $primaryColor;
                }

                .mat-radio-ripple {
                    display: none;
                }
            }
        }
    }
}
