@import '~assets/scss/variables';
@import '~assets/scss/mixins/border';
@import '~assets/scss/mixins/fonts';

.mat-form-field {
    $border-radius: map-get($border-radii, 'xxs');

    .mat-form-field-infix { // Some weird layout hack by angular material library, we'll have to correct it
        border-top: 0;
        margin-top: $border-radius / 2;
        padding: 0;
        line-height: 5.6rem;
    }

    .mat-error {
        color: $boom-ho-signal-red;
        @include poppins-medium(1.5rem, 2.5rem);
    }

    &.mat-form-field-appearance-outline {
        &.mat-form-field-invalid {
            .mat-form-field-outline-thick {
                color: $boom-ho-red-light;
            }
        }

        .mat-form-field-outline-start {
            @include border-radius-left($border-radius);
        }

        .mat-form-field-outline-end {
            @include border-radius-right($border-radius);
        }

        .mat-form-field-outline-start,
        .mat-form-field-outline-end {
            min-width: $border-radius;
        }

        &.mat-focused:not(&.mat-form-field--disabled) {
            .mat-form-field-outline-start,
            .mat-form-field-outline-end {
                border-color: $primaryColor;
            }
        }

        .mat-form-field-outline-start,
        .mat-form-field-outline-gap,
        .mat-form-field-outline-end {
            background-color: $boom-ho-white;
        }

        .mat-form-field-wrapper {
            margin: 0;
            padding-bottom: 1.5em;

            .mat-form-field-subscript-wrapper {
                margin-top: 0.1rem;
            }
        }

        .mat-form-field-flex {
            &:hover {
                .mat-form-field-outline {
                    color: $boom-ho-gray-light;
                }
            }
        }

        &.mat-focused .mat-form-field-outline-thick {
            color: $boom-ho-gray-light;
        }
    }

    &--header-edit {
        .mat-form-field-infix {
            line-height: 6rem;
        }

        .mat-error {
            font-size: 1rem;
        }

        &.mat-form-field-appearance-outline {
            .mat-form-field-outline {
                color: transparent;
                top: 6.5rem;
            }

            .mat-form-field-flex {
                &:hover {
                    .mat-form-field-outline {
                        color: transparent;
                    }
                }
            }

            &.mat-form-field-invalid {
                .mat-form-field-outline-thick {
                    display: none;
                }
            }

            .mat-form-field-outline-start,
            .mat-form-field-outline-gap,
            .mat-form-field-outline-end {
                background-color: transparent;
            }

            .mat-form-field-flex {
                padding-left: 0;
            }

            .mat-form-field-wrapper {
                padding-bottom: 1.5em;

                .mat-form-field-subscript-wrapper {
                    margin-top: -1rem;
                    padding: 0;
                }
            }
        }
    }

    &--search-books {
        &.mat-form-field-invalid {
            .mat-form-field-outline-thick {
                color: #dddddd!important;
            }
        }

        .mat-form-field-wrapper {
            padding-bottom: 0!important;
        }
    }

    &--search-bar {
        position: absolute;
        width: 100%;
        right: 0;

        .mat-input-element {
            margin-top: 0;
        }

        .mat-form-field-label-wrapper {
            padding-top: 0;
            top: 0;
        }

        .mat-form-field-infix {
            margin-top: 0;
        }

        // Override some vendor styling
        &.mat-form-field,
        &.mat-form-field-appearance-outline {
            .mat-form-field-wrapper {
                padding-bottom: 0;

                .mat-form-field-subscript-wrapper {
                    margin-top: 0;
                }
            }

            .mat-form-field-flex {
                padding: 0 5.6rem 0 1.6rem;
            }

            .mat-form-field-outline {
                top: 0;
            }

            .mat-input-element,
            .mat-form-field-infix,
            .mat-form-field-flex {
                margin-top: 0;
            }
        }
    }

    &--collapsed {
        width: 0;
    }

    &--disabled {
        &.mat-form-field-appearance-outline {
            .mat-form-field-outline-start,
            .mat-form-field-outline-gap,
            .mat-form-field-outline-end {
                background-color: $boom-ho-gray-lighter;
            }
        }
    }

    // TODO: Why are these fixes necessary???
    &--form-exam-assign {
        &.mat-form-field-appearance-outline {
            &.mat-focused:not(&.mat-form-field--disabled) {
                .mat-form-field-outline-start,
                .mat-form-field-outline-end {
                    border-color: $boom-ho-orange;
                }
            }

            .mat-select-arrow-wrapper {
                transform: translateY(0);
            }
        }

        .mat-form-field-infix {
            display: flex;
            margin-left: 0.5rem;
        }

        .mat-form-field-prefix {
            line-height: 5.6rem;
            top: 0.18rem !important;
        }

        app-icon.select-input-icon {
            margin-right: 1rem;
        }

    }
}
