@import '~assets/scss/variables';
@import '~assets/scss/mixins/fonts';

// theming
$borderColor: var(--text-color, $boom-ho-secondary);
$contentMarkColor: var(--content-mark-color, $boom-ho-white);

.document-select-block {
    $document-select-block: &;

    @mixin appearance($value: none) {
        -webkit-appearance: $value;
        -moz-appearance: $value;
        appearance: $value;
    }

    &__row {
        border: 0.1rem solid $boom-vo-secondary;
        @include poppins-regular;
        background-color: $boom-ho-white;
        min-height: 6.4rem;
        border-radius: 0.4rem;
        margin: 0 0 1rem 0;
        cursor: pointer;

        &:last-child {
            margin-bottom: 0;
        }

        &--image {
            display: flex;
        }
    }

    &__models {
        display: grid;
        gap: 1rem;
        margin-top: 1rem;
        margin-left: -2rem;
    }

    &--correct-answer {
        // need max height when correct-answer is broken
        .document-select-block__row {
            max-height: 100vh;
        }
    }

    &__left {
        border-right: 1px solid $boom-vo-secondary;
        min-height: 100%;
    }

    &__right {
        margin: 1.3rem 1.6rem 1.3rem 1.2rem;
        min-height: 100%;

        .input-inline {
            line-height: 4.6rem;
            @-moz-document url-prefix() {
                margin-bottom: -2rem;
            }
        }
    }

    .container {
        @include appearance();
        display: block;
        min-width: 2rem;
        min-height: 2rem;
        border: 0.1rem solid $boom-ho-gray;
        border-radius: 0.4rem;
        background-color: $boom-ho-gray-lighter;
        margin: 0 1.6rem 0 1.6rem;
        position: relative;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .checkmark {
        @include appearance();
        position: absolute;
        top: 0;
        left: 0;
        height: 2rem;
        width: 2rem;
    }

    .container input:checked ~ .checkmark {
        margin: -0.1rem 0 0 -0.1rem;
        border-radius: 0.4rem;
        background-color: $contentMarkColor;
        border: 0.1rem solid $borderColor;
    }

    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    .container input:checked ~ .checkmark:after {
        display: block;
    }

    .container .checkmark:after {
        left: 0.7rem;
        top: 0.2rem;
        width: 0.5rem;
        height: 1rem;
        border: solid $borderColor;
        border-width: 0 0.2rem 0.2rem 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    &__radio {
        @include appearance();
        min-width: 2rem;
        min-height: 2rem;
        border: 0.1rem solid $boom-ho-gray;
        background-color: $boom-ho-gray-lighter;
        margin: 0 1.6rem 0 1.6rem;
        position: relative;

        &:checked {
            background-color: $contentMarkColor;
            border: 0.1rem solid $borderColor;
        }

        &:checked:after {
            display: flex;
            content: '';
            background-color: $borderColor;
            width: 1rem;
            height: 1rem;
            margin: 0.4rem;
        }

        &:checked:before {
            background-color: $borderColor;
        }
        border-radius: 100%;

        &:checked:after {
            border-radius: 100%;
        }
    }

    &__image {
        flex: 1;
        text-align: center;

        img {
            max-height: 20rem;
            max-width: 100%;
        }
    }

    &--correct-answer-vo {
        .document-select-block__row--active {
            color: $boom-ho-orange;
            border-color: $boom-ho-orange;
        }

        .container input:checked ~ .checkmark {
            background-color: $boom-ho-orange-select-box;
            border: 0.1rem solid $boom-ho-orange;
        }

        .container .checkmark:after {
            left: 0.7rem;
            top: 0.2rem;
            width: 0.5rem;
            height: 1rem;
            border: solid $boom-ho-orange;
            border-width: 0 0.2rem 0.2rem 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }

        .document-select-block__checkbox,
        .document-select-block__radio {
            border: 0.1rem solid $boom-ho-orange;

            &:checked {
                background-color: $boom-ho-orange-select-box;
            }

            &:checked:after {
                background-color: $boom-ho-orange;
            }

            &:checked:before {
                background-color: $boom-ho-orange;
            }
        }
    }

    .document-select-block__row--correct {
        border: 0.1rem solid $boom-ho-green;
        background-color: $boom-ho-green-question;

        .document-select-block__radio {
            border: 0.1rem solid $boom-ho-green;

            &:after {
                display: flex;
                content: '';
                background-color: $boom-ho-green;
                width: 1rem;
                height: 1rem;
                margin: 0.4rem;
                border-radius: 100%;
            }
        }

        document-select-block .container .checkmark:after {
            border: solid $boom-ho-green;
        }

        .container {
            border: 0.1rem solid $boom-ho-green;
            background-color: $boom-ho-green-question;

            .checkmark {
                left: 0.7rem !important;
                top: 0.2rem !important;
                width: 0.5rem !important;
                height: 1rem !important;
                border: solid $boom-ho-green !important;
                border-width: 0 0.2rem 0.2rem 0 !important;
                -webkit-transform: rotate(45deg) !important;
                -ms-transform: rotate(45deg) !important;
                transform: rotate(45deg) !important;
                border-radius: unset !important;
                background-color: unset !important;
            }
        }

        .container .checkmark:after {
            display: none !important;
        }

        .document .document-select-block .container input:checked ~ .checkmark {
            border-radius: unset !important;
            background-color: transparent !important;
        }
    }

    .document-select-block__row--incorrect {
        border: 0.1rem solid $boom-ho-red;
        background-color: $boom-ho-red-question;

        .document-select-block__radio {
            border: 0.1rem solid $boom-ho-red;

            &:after {
                display: flex;
                content: '';
                background-color: $boom-ho-red;
                width: 1rem;
                height: 1rem;
                margin: 0.4rem;
                border-radius: 100%;
            }
        }

        .container input:checked ~ .checkmark {
            background-color: $boom-ho-red-question;
            border: 0.1rem solid $boom-ho-red;
        }

        document-select-block .container .checkmark:after {
            border: solid $boom-ho-red;
        }

        .checkmark:after {
            left: 0.7rem;
            top: 0.2rem;
            width: 0.5rem;
            height: 1rem;
            border: solid $boom-ho-red;
            border-width: 0 0.2rem 0.2rem 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }

    &__initial,
    &__reviewed,
    &__correct {
        .assignment-answer {
            font-size: 1.2rem;
        }
    }

    &__initial {
        .document-select-block__row {
            .document-select-block__checkbox:checked ~ .checkmark {
                border: 0.1rem solid $boom-ho-gray;
                background-color: initial;

                &:after {
                    border-color: $boom-ho-gray;
                }
            }

            &--active {
                color: inherit;
                border-color: $boom-ho-gray;
            }
        }
    }

    &__reviewed {
        .document-select-block__row {
            .document-select-block__checkbox:checked ~ .checkmark {
                border: 0.1rem solid $boom-vo-primary;
                background-color: initial;


                &:after {
                    border-color: $boom-vo-primary;
                }
            }

            &--active {
                color: inherit;
                border-color: $boom-vo-primary;
            }
        }
    }

    &__correct {
        .document-select-block__row {
            .document-select-block__checkbox:checked ~ .checkmark {
                background-color: $boom-ho-orange-select-box;
                border: 0.1rem solid $boom-ho-orange;

                &:after {
                    border-color: $boom-ho-orange;
                }
            }

            &--active {
                color: inherit;
                border-color: $boom-ho-orange;
            }
        }
    }
}
