@import '~assets/scss/variables';
@import '~assets/scss/mixins/fonts';
@import "~assets/scss/mixins/appearance";

// theming
$borderColor: var(--text-color, $boom-ho-secondary);
$contentMarkColor: var(--content-mark-color, $boom-ho-white);

.document__single-input-inline {
    display: inline-flex;
    align-items: center;
    margin: 0.1rem 0.5rem;

    .select-wrapper {
        position: relative;
        display: inline-block;

        &:before {
            font-family: 'boomho';
            content: "\eb0c";
            color: $borderColor;
            position: absolute;
            font-size: 3.2rem;
            right: 1rem;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
            cursor: pointer;
            pointer-events: none;
        }
    }

    .sr-only {
        display: none;
    }

    .select-inline {
        @include appearance();
        @include poppins-regular(1.6rem);
        display:  block;
        position: relative;
        background: $boom-ho-white;
        border: 0.1rem solid $boom-ho-select-inline-border;
        box-shadow: inset 0 -0.2rem 0 $boom-ho-select-inline-boxshadow;
        border-radius: 0.4rem;
        height: 4.8rem;
        padding: 0.3rem 4rem 0.3rem 1rem;
        outline: 0;
        cursor: pointer;

        &__answers {
            display: inline-flex;
            flex-wrap: wrap;
            gap: 0.5rem;
        }

        &__correct-answer,
        &__reviewed-answer,
        &__initial-answer {
            padding: 0 1rem;
            display: inline-flex;
            align-items: center;
            line-height: 1.5rem;
            cursor: default;
            min-width: 8rem;
            margin: 0.5rem 0;

            > span {
                white-space: nowrap;
            }

            &:before {
                @include border-radius(0.3rem);
                content: attr(data-label);
                white-space: nowrap;
                position: absolute;
                font-size: 0.75rem;
                text-transform: uppercase;
                display: block;
                line-height: 1.4rem;
                padding: 0 .4rem;
                top: -0.7rem;
                left: 0.4rem;
                color: #FFF;
            }
        }

        &__correct-answer {
            color: $boom-ho-orange !important;
            border-color: $boom-ho-orange;

            &:before {
                background-color: $boom-ho-orange;
            }
        }

        &__reviewed-answer {
            color: $boom-vo-primary !important;
            border-color: $boom-vo-primary;

            &:before {
                background-color: $boom-vo-primary;
            }
        }

        &__initial-answer {
            color: $boom-ho-gray !important;
            border-color: $boom-ho-gray;

            &:before {
                background-color: $boom-ho-gray;
            }
        }
    }

    &--has-value .select-inline {
        color: $boom-ho-secondary;
    }

    &--table {
        .select-inline {
            display: block;

            &__correct-answer {
                margin: 0.5rem 0 0 0;
            }

            &__initial-answer {
                margin: 0 0 0.5rem 0;
            }
        }
    }
}
