@import 'include-media';

.rsbtn_colorskin {
  text-decoration: none !important;
  margin-left: 0.5rem;

  $read-speaker: &;

  [title^='Volume'], [title^='Stop'] {
    display: none !important;
  }

  .rs-player-loadindicator {
    display: none !important;
  }
}

.rsplaying > .rsbtn_colorskin {
  width: 0 !important;
  padding: 0 !important;
  border-radius: unset !important;
  border: none !important;
}

.rsbtn_tooltoggle {
  margin-left: 4rem
}

.rs_addtools .rsbtn_play, .rsbtn {
  border: none !important;
  border-radius: unset !important;
}

.rsbtn_player_item {
  border: 0 !important;
  color: inherit !important;
}

.rs_addtools .rsbtn_exp, .rsbtn .rsbtn_exp {
  margin-top: -1rem;
  margin-left: 3rem !important;
}

.rsbtn_toolpanel {
  position: absolute !important;
}

.rs_addtools.mega_toggle .rsbtn_tooltoggle, .rsbtn.mega_toggle .rsbtn_tooltoggle {
  margin-left: 4rem !important;
  border: 0 !important;
  background: unset;

  .rsicn {
    border: 0.2rem solid rgba(0, 36, 57, 0.08) !important;
    border-radius: 0.4rem !important;
    height: 2.8rem;
    width: 3.5rem;
    padding-top: 0.7rem;
    margin-left: -6.2rem !important;
    margin-top: -1.3rem !important;
  }
}

.rs_addtools.mega_toggle .rsbtn_tooltoggle, .rsbtn.mega_toggle .rsbtn_tooltoggle {
  margin-left: 4rem !important;
  border: 0px !important;
  background: unset;
}


.rsbtn .rsbtn_exp .rsbtn_exp_inner .rsbtn_pause span.rsicn {
  width: 3.5rem !important;
  height: 3.5rem !important;
  margin-left: -3.5rem!important;

  &::before {
    font-family: 'boomho' !important;
    content: '\eb1a' !important;
    font-size: 2rem !important;
  }
}

.rsbtn .rspaused .rsbtn_exp .rsbtn_exp_inner .rsbtn_pause span.rsicn, .rsbtn.rsidle .rsbtn_exp .rsbtn_exp_inner .rsbtn_pause .rsicn::before, .rsbtn.rspaused .rsbtn_exp .rsbtn_exp_inner .rsbtn_pause .rsicn::before {
  font-family: 'boomho' !important;
  content: '\e986' !important;
  font-size: 2rem !important;
}

.rsbtn .rsbtn_exp .rsbtn_exp_inner .rsbtn_closer span.rsicn {
  width: 2.4rem !important;
  height: 2.4rem !important;
  background-color: #ECEDEF !important;
  margin-left: 0.5rem !important;

  @include media(">phone") {
    margin-left: 3.8rem !important;
    margin-top: -3.8rem !important;
  }
}

.rsbtn .rsbtn_exp .rsbtn_exp_inner {
  background: none !important;
  border: unset !important;
}

.rs_addtools.mega_toggle:focus .rsbtn_closer span.rsicn, .rsbtn.mega_toggle .rsbtn_closer span.rsicn, .rs_addtools.mega_toggle:hover .rsbtn_closer span.rsicn, .rsbtn.mega_toggle .rsbtn_closer span.rsicn, .rsbtn .rsbtn_exp .rsbtn_exp_inner .rsbtn_player_item span.rsicn, .rs_addtools.mega_toggle.zoom-tooltoggler .rsbtn_tooltoggle span.rsicn, .rsbtn.mega_toggle .rsbtn_tooltoggle span.rsicn,.rs_addtools.mega_toggle.zoom-tooltoggler:hover .rsbtn_tooltoggle span.rsicn, .rsbtn.mega_toggle .rsbtn_tooltoggle span.rsicn {
  font-size: 1.3em !important;
  color: black !important;
}

.rs_addtools .rsbtn_tooltoggle:hover, .rsbtn .rsbtn_tooltoggle:hover, rs_addtools .rsbtn_tooltoggle:focus, .rsbtn .rsbtn_tooltoggle:focus, .rs_addtools .rsbtn_closer:hover, .rsbtn .rsbtn_closer:hover, .rs_addtools .rsbtn_closer:focus, .rsbtn .rsbtn_closer:focus {
  background: none !important;
}

.rsbtn_player_item:hover, .rsbtn .rsbtn_exp .rsbtn_exp_inner .rsbtn_player_item:focus {
  background-color: unset !important;
}

.rsicn:hover {
  cursor: pointer !important;
}

.rsbtn_closer {
  margin-left: 2.04rem !important;
  margin-top: 0.5rem !important;

  .rsicn {
    border: unset !important;
    border-radius: 100% !important;

    &::before {
      font-family: 'boomho' !important;
      content: '\eacc' !important;
      color:grey !important;
      font-size: 1.12rem !important;
      position: absolute;
      top: 0.65rem;
      right: 0.65rem;
    }
  }
}

.rs_addtools .rsbtn_tooltoggle .rsicn::before, .rsbtn .rsbtn_tooltoggle .rsicn::before {
  margin-top: 0.5rem;
  font-size: 1.8rem !important;
  font-family: 'boomho' !important;
  content: '\eb19' !important;
}
