@import '~@angular/flex-layout/mq';
@import '~assets/scss/documents/variables';
@import '~assets/scss/mixins/fonts';
@import '~assets/scss/mixins/typology';

$moduleColor: var(--text-color, $boom-ho-gray);

.document {
    @import '~assets/scss/documents/list';
    @import '~assets/scss/documents/typology';
    @import '~assets/scss/documents/content-mark';
    @import '~assets/scss/documents/media';
    @import '~assets/scss/documents/reference';
    @import '~assets/scss/documents/assignment';
    @import '~assets/scss/documents/content-case';
    @import '~assets/scss/documents/table';
    @import '~assets/scss/documents/term';
    @import '~assets/scss/documents/minitoc';
    @import "~assets/scss/documents/select-block";
    @import "~assets/scss/documents/sortable-block";
    @import "~assets/scss/documents/input-inline";
    @import "~assets/scss/documents/matching";
    @import "~assets/scss/documents/hotspot";
    @import "~assets/scss/documents/graphic-gat-match";
    @import "~assets/scss/documents/multi-matching";
    @import "~assets/scss/documents/single-select-inline";
    @import "~assets/scss/documents/hint";
    @import "~assets/scss/documents/feedback";

    &__section[data-level="1"] {
        @include layout-bp(gt-xs) {
            max-width: 77.6rem;
        }
    }

    &__rtti {
        color: $moduleColor;
        font-size: 1.2rem;
        margin-left: 0.5rem;
    }

    &-inline-verwijzing {
        .assignment-heading {
            display: none;
        }
    }

    i {
        margin-right: 0.2rem;
    }

    pre {
        display: block;

        .hljs {
            width: 100%;
            display: block;
            background-color: $boom-ho-white;
            border-radius: 1.5rem;
        }
    }
}
