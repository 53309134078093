@import '~assets/scss/variables';
@import '~assets/scss/mixins/transform';
@import '~assets/scss/mixins/fonts';

$textColor: var(--primary-color, $boom-ho-secondary);

.cdk-drop-list {
    // Drop list containing drag drop items

    .cdk-drag {
        // Item in list
        @include cursor-grab();
        transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
        border-bottom: 0.1rem solid $boom-ho-secondary;
        display: block;

        &--border-blue {
            border-bottom: $boom-ho-secondary;
        }
    }

    .cdk-drag-placeholder {
        // Placeholder displayed in list
        border-bottom-color: transparent;

        &:hover {
            background-color: $boom-ho-white;
        }

        > * {
            // Hide any element inside placeholder
            visibility: hidden;
        }
    }

    &.cdk-drop-list-dragging {
        // Class added to list when dragging items
        .cdk-drag:hover {
            background-color: $boom-ho-white;
        }
    }
}

.cdk-drag-preview {
    // Rendered next to user's cursor
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    line-height: 2rem;
    padding: 1.25rem 5.1rem 1.25rem 2rem;
    box-sizing: border-box;
    background-color: $boom-ho-white;
    border: 0.1rem solid $boom-ho-matching-border-color;
    border-radius: 0.4rem;

    &:before {
        font-family: 'boomho';
        content: "\eb14";
        font-size: 1.4rem;
        color: $textColor;
        display: flex;
        align-items: center;
        position: absolute;
        right: 1.6rem;
        height: 100%;
    }

    img,
    figure {
        display: flex;
        justify-content: center;
        margin: 0;
    }

    &.document-sortable-block__item {
        padding: 1.25rem 5.1rem 1.25rem 0;
    }

    .document-sortable-block__number {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 4.8rem;
        height: 100%;
        color: $textColor;

        &:after {
            content: '';
            position: absolute;
            border-right: 0.1rem solid $boom-ho-gray-lighters;
            height: 100%;
            left: 4.5rem;
        }

        &:nth-child(2):after {
            left: 9rem;
        }
    }

    .document-sortable-block__label {
        margin-left: 1.2rem;
    }
}

.cdk-drag-animating {
    // Animates item that's dropped
    transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
}

// styling for specific cases
.cdk-drag.document-multi-matching-block__choice {
    @include poppins-regular(1.6rem);
    display: flex;
    justify-content: center;
    position: relative;

    &.cdk-drag-preview{
        app-document-zoomimage {
            display: none;
        }
    }

    document-jit-content {
        img {
            max-height: 39rem;
        }
    }

    &:after {
        font-family: 'boomho';
        content: "\eb14";
        font-size: 1.4rem;
        display: flex;
        align-items: center;
        position: absolute;
        right: 1.6rem;
        height: 100%;
        z-index: 2;
        padding-left: 1rem;
        border-left: 0.1rem solid $boom-ho-secondary;
    }

    &:hover:after {
        color: $textColor;
    }
}
