@import '~assets/scss/variables';

.red-snackbar {
    background-color: $boom-ho-red;
    color: $boom-ho-white !important;
    border: none;
}

.white-snackbar {
    background-color: $boom-ho-white;
    border: none;

    &, .mat-button {
        color: $boom-ho-black !important;
    }
}
