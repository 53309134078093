@mixin font-face($name, $file, $weight) {
    @font-face {
        font-family: $name;
        font-style: normal;
        font-weight: $weight;
        font-display: swap;
        src: url($file) format('truetype');
    }
}

@mixin font-family($family, $weight, $size, $lineHeight) {
    font-family: $family, sans-serif;
    font-weight: $weight;
    font-size: $size;

    @if $lineHeight {
        line-height: $lineHeight;
    }
}

@mixin poppins-regular($size: 1.6rem, $lineHeight: false) {
    @include font-family(Poppins, 400, $size, $lineHeight);
}

@mixin poppins-medium($size: 1.6rem, $lineHeight: false) {
    @include font-family(Poppins, 500, $size, $lineHeight);
}

@mixin poppins-semi-bold($size: 1.6rem, $lineHeight: false) {
    @include font-family(Poppins, 600, $size, $lineHeight);
}

@mixin poppins-bold($size: 1.6rem, $lineHeight: false) {
    @include font-family(Poppins, 700, $size, $lineHeight);
}

// Montserrat Alternates
@mixin montserrat-alternates-regular($size: 1.6rem, $lineHeight: false) {
    @include font-family(MontserratAlternates, 400, $size, $lineHeight);
}

@mixin montserrat-alternates-medium($size: 1.6rem, $lineHeight: false) {
    @include font-family(MontserratAlternates, 500, $size, $lineHeight);
}

@mixin montserrat-alternates-semi-bold($size: 1.6rem, $lineHeight: false) {
    @include font-family(MontserratAlternates, 600, $size, $lineHeight);
}

@mixin montserrat-alternates-bold($size: 1.6rem, $lineHeight: false) {
    @include font-family(MontserratAlternates, 700, $size, $lineHeight);
}
