.mat-select-panel-wrap {
    .mat-select-panel {
        .mat-optgroup {
            .mat-option.mat-active:not(:hover) {
                background: inherit !important;
            }

            &--selectable {
                .mat-optgroup-label {
                    display: flex;
                    cursor: pointer;
                    height: 5.6rem;
                    line-height: 5.6rem;
                }

                .mat-pseudo-checkbox {
                    order: -2;
                    align-self: center;
                    margin-right: 0.8rem;
                }

                .icon {
                    order: -1;
                    margin: auto 0.5rem auto 0;
                }
            }

            &--some-selected {
                .mat-optgroup-label {
                    .mat-pseudo-checkbox:after {
                        top: 0.9rem;
                        height: 0;
                        transform: unset;
                    }
                }
            }

            &-toggle {
                &--up:before,
                &--down:before {
                    position: absolute;
                    font-family: 'boomho';
                    font-size: 2rem;
                    color: #757575;
                    right: 2rem;
                    transform: translate(15%, 60%);
                }

                &--up:before {
                    content: '\eaf2';
                }

                &--down {
                    &:before {
                        content: '\eaf5';
                    }

                    > * {
                        display: none;
                    }
                }
            }

            &--hidden {
                display: none;
            }

            .mat-pseudo-checkbox {
                background-color: $boom-ho-gray-lighter;
                border: 0.1rem solid $boom-ho-gray-border;
                border-radius: 0.5rem;
                padding: 1rem;

                &-checked {
                    background-color: $select-box-background;
                    border-color: $select-box-checked;

                    &::after {
                        top: 0.6rem;
                        left: 0.5rem;
                        color: $select-box-checked;
                    }
                }
            }
        }

        .mat-option {
            &--indent {
                padding-left: 5rem;

                .mat-option-text {
                    display: inline;
                    padding-left: 1rem;
                    color: $boom-ho-gray-dark;
                }
            }

            &--large {
                line-height: 5.6rem;
                height: 5.6rem;
            }

            &--hide-checkbox {
                .mat-pseudo-checkbox {
                    display: none;
                }
            }

            &--hidden {
                display: none !important;
            }
        }
    }
}
