@import '~assets/scss/variables';

.document-multi-matching-block {
    $border-radius: 0.3rem;
    $max-block-height: 1000rem;
	margin-top: 2em;
	margin-bottom: 2em;

	&.document-matching-block--has-correct-answer {
		.document-multi-matching-block__answerblock {
			display: flex;
		}
	}

	.document-multi-matching-block__answerblock {
		.document__question-answer {
			flex: 1;
			.document-matching-block__body {
				position: relative;
				overflow: hidden;
				flex-direction: column;
				display: flex;
				margin-top: 1em;
			}
		}
	}

	.document-multi-matching-block__matches__matchzone {
		.document-multi-matching-block--header {
			.document__content-media {
                display: flex;
                justify-content: center;

				.document-content-media__container {
                    border-radius: 0.5rem;
                    border: 0.1rem solid $boom-ho-secondary;
					padding: 0.3rem;
                    margin: 2.5rem 0;
					img {
						border: 0 none;
					}
					figcaption {
						text-align: center;
                        background-color: transparent;
                        color: $boom-ho-secondary;
                        line-height: 0;
                        margin-top: 0;
					}
				}
			}
		}
	}

	&__choice.document-multi-matching-block-is-image {
		.document-content-media {
			flex: 1;
			display: flex;
            justify-content: center;
			.document-content-media__container {
                margin: 2.5rem 0;
                max-height: 25rem;

				figcaption {
					max-width: 100% !important;
				}
			}
		}
	}

	.choice-image {
		max-width: 100%;
	}

	p {
		margin-bottom: 0;
        margin-top: 0;
	}

	&__answerblock {
		.document__question-answer {
			background-color: rgba(248, 135, 4, 0.2);
            margin: 2rem 0;
			border: none;
			.document-matching-block__body {
                padding: 2rem 1rem 1rem;
			}
		}
	}

	.document__question-answer {
		padding: 0;
		&.document-multi-matching-block__givenanswers {
			.document-matching-block__body {
				padding-right: 0.3rem;
			}
		}
	}

    .document-multi-matching-block {

        &__answerblock {
            .document__question-answer {
                background-color: $boom-ho-white;
            }
        }

        &-correct {
            border: 0.1rem solid $boom-ho-orange !important;
            position: relative;
            margin: 1rem 0;

            &:before {
                @include label-style;
                background-color: $boom-ho-orange;
            }

            .document-multi-matching-block__choice {
                border: 0.1rem solid $boom-ho-orange !important;
                max-height: $max-block-height;
            }
        }

        &-reviewed {
            border: 0.1rem solid $boom-vo-primary !important;
            position: relative;
            margin: 1rem 0;

            &:before {
                @include label-style;
                background-color: $boom-vo-primary;
            }

            .document-multi-matching-block__choice {
                border: 0.1rem solid $boom-vo-primary !important;
            }
        }

        &-initial {
            border: 0.1rem solid $boom-ho-gray !important;
            position: relative;
            margin: 1rem 0;

            &:before {
                @include label-style;
                background-color: $boom-ho-gray;
            }

            .document-multi-matching-block__choice {
                border: 0.1rem solid $boom-ho-gray !important;
            }
        }
    }

	.document__question-answer {
		&.document-multi-matching-block__correctanswers {
			.document-matching-block__body {
				.document-multi-matching-block__choice {
					border: 0.1rem solid transparent !important;
					color: $boom-ho-orange;
					&.document-multi-matching-block-is-image {
						border: 0.1rem solid $boom-ho-orange !important;
						.document-enlarge {
							border-color: $boom-ho-orange !important;
						}
						figcaption {
							color: $boom-ho-orange !important;
						}
					}
				}
			}
		}
	}

    &__answerblock {
        &:first-child {
            border-top-right-radius: $border-radius;
        }
        &:last-child {
            border-bottom-right-radius: $border-radius;
        }

        &--text {
            border-color: $boom-ho-orange;
        }

        &--image {
            background-color: $boom-ho-gray-light;

            img {
                border-color: $boom-ho-orange;
            }
        }
    }


	// cdkDragDrop classes
	.cdk-drag-preview {
		box-sizing: border-box;
		border-radius: 0.4rem;
		box-shadow: 0 0.5rem 0.5rem -0.3rem rgba(0, 0, 0, 0.2),
		0 0.8rem 1rem 0.1rem rgba(0, 0, 0, 0.14),
		0 0.3rem 1.4rem 0.2rem rgba(0, 0, 0, 0.12);
		position: static;
	}

	.cdk-drag-placeholder {
		opacity: 0;
	}

	.cdk-drag-animating {
		transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
	}

	// Overwrites
	.cdk-drop-list .cdk-drag {
		transition: none;
	}

	&__body {
		display: flex;
		flex-direction: row;
		border: 0.1rem solid $boom-ho-gray-light;
		border-radius: 0.5rem;
	}

	&__matches,
	&__choices {
		flex: 1;
		min-height: 3em;
	}

	&__matches {
		border-right: 0.1rem solid $boom-ho-gray-light;
	}

	&__choices {
		padding-top: 0.3rem;
	}

	.is-stacked {
		position: relative;
		min-height: 50rem;
		.document-multi-matching-block__choice {
			position: absolute;
			width: calc(100% - 1.2rem);
			&.not-top-image {
				filter: brightness(50%);
			}
		}
	}

	&--header {
		padding: 0.5em 1em;
		background-color: $boom-ho-white;
		p {
			margin-bottom: 0;
		}
	}

	&--body {
		padding-top: 0.3rem;
		min-height: 5em;
		border: 0.1rem solid $boom-ho-gray-lightest;
		border-bottom-left-radius: $border-radius;
		border-bottom-right-radius: $border-radius;
		background-color: $boom-ho-gray-lightest ;
	}

	&__choice {
        border: 0.1rem solid $boom-ho-secondary !important;
        margin: 0.6rem;
        text-align: center;
        background-color: $boom-ho-white;
        border-radius: 0.5rem;
        padding-right: 4.6rem;

        .document-content-media__container figcaption {
            background-color: transparent;
            color: $boom-ho-secondary;
            line-height: 0;
            border-radius: 0;
            padding: 0;
            margin-top: 0;
        }
    }

    &__body--initial {
        margin-top: 2rem;
    }

	&.document-matching-block--hide-initial-answer {
		display: none;
	}


	&--has-titles &__match,
    &--has-titles &__choice,
    &--has-titles &__correct-answer,
    &--has-titles &__initial-answer {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
}

.document-multi-matching-block-is-image {

	.document__content-media {
		margin: 0;
		.document-content-media {
			.document-content-media__container {
				img {
					border: 0 none;
				}
				figcaption {
					margin-top: 0;
					text-align: center;
				}
			}
		}
	}
	&:hover {
		background-color: $boom-ho-white !important;
	}
}
