.mat-datepicker-toggle,
.mat-datepicker-toggle-active {
    color: $boom-ho-orange;
}

.mat-calendar-body-selected {
    background-color: $boom-ho-orange;
    color: $boom-ho-white;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > {
    .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
        background-color: $boom-ho-orange-select-box;
    }
}
