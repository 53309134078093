@import '~assets/scss/variables';
@import '~assets/scss/mixins/border';
@import '~assets/scss/mixins/fonts';

mat-card.mat-card {
    display: flex;
    border: 0.1rem solid $boom-ho-gray-lighters;
    border-radius: 0.8rem;

    &:not([class*=mat-elevation-z]) {
        box-shadow: 0 0.1rem 0.2rem $boom-ho-rgba-light;
    }

    mat-card-subtitle {
        margin-bottom: 0;
    }

    &--side-line {
        padding-left: 0;
    }
}
