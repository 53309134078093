@import '~assets/scss/variables';
@import 'include-media';

@mixin color-modifiers($prefix: '', $attribute: 'color') {
    @include media(">phone") {
        @if '' != $prefix {
            $prefix: #{$prefix + '-'};
        }

        @each $name, $value in $color-map {
            &#{'--' + $prefix + $name} {
                #{$attribute}: $value;
            }
        }
    }
}

@mixin color-bg-modifiers {
    @include color-modifiers('bg', 'background-color');
}
