@mixin transform($properties) {
    -webkit-transform: $properties;
    -moz-transform: $properties;
    -o-transform: $properties;
    -ms-transform: $properties;
    transform: $properties;
}

@mixin transform-rotate($degrees) {
    @include transform(rotate($degrees));
}

@mixin user-select($value) {
    -webkit-user-select: $value; /* Chrome/Safari */
    -moz-user-select: $value; /* Firefox */
    -ms-user-select: $value; /* IE10+ */
    -o-user-select: $value;
    user-select: $value;
}

@mixin cursor-grab() {
    cursor: move;
    cursor: grab;
    cursor: -webkit-grab;
}

@mixin box-shadow($properties) {
    -webkit-box-shadow: $properties;
    -moz-box-shadow: $properties;
    box-shadow: $properties;
}
