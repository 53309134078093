@import '~assets/scss/variables';
@import '~assets/scss/mixins/fonts';
@import 'include-media';

$contentMarkColor: var(--content-mark-color, $boom-ho-white);
$iconBackgroundColor: var(--text-color, $boom-ho-gray-lighter);

.document-reference {
    &__loading {
        margin: auto 0;
    }

    &__wrapper {
        flex-direction: row;
        box-sizing: border-box;
        display: flex;
        background-color: $boom-ho-white;
        border: 0.2rem solid rgba(0, 36, 57, 0.16);
        border-radius: 1.2rem;
        min-height: 9.6rem;
        margin-bottom: 0.5rem;
        padding: 1.2rem 2.4rem 1.2rem 2.4rem;
        cursor: pointer;

        @include media(">phone") {
            min-width: 45rem;
        }

        &__icon-wrapper {
            flex: 1 1 4.9rem;
            box-sizing: border-box;
            max-width: 4.9rem;
            min-width: 4.9rem;
            display: flex;
            align-items: center;
            justify-content: left;
            color: $boom-ho-primary;

            @include media(">phone") {
                flex: 1 1 10.8rem;
                box-sizing: border-box;
                max-width: 10.8rem;
                min-width: 10.8rem;
                display: flex;
                align-items: center;
                justify-content: left;
                color: $boom-ho-primary;
            }

            &__icon {
                font-size: 3.2rem;
                display: flex;
                align-items: center;
                justify-content: center;

                @include media(">phone") {
                    font-size: 6rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        &__other {
            text-align: center;
            margin-right: 3.2rem;
            color: $boom-ho-gray;

            &-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                color: $iconBackgroundColor;
            }

            &-block {
                height: 3rem;
                width: 3rem;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $contentMarkColor;
                color: $iconBackgroundColor;
                border-radius: 1.2rem;

                &-size {
                    font-size: 1.5rem;

                    @include media(">phone") {
                        font-size: 3rem;
                    }

                    &--icon {
                        display: flex;
                        flex: 1 1 0%;
                        box-sizing: border-box;
                    }
                }

                @include media(">phone") {
                    height: 6rem;
                    width: 6rem;
                }

                &--anywyse,
                &--drillster {
                    background-color: $boom-vo-drillster-background-color;
                    color: $boom-vo-primary;
                }
            }
        }

        &__text-wrapper {
            display: block;
            align-items: center;
            justify-content: left;
            width: 1rem;
            flex: 1 1 0%;
            box-sizing: border-box;

            &-text {
                max-width: 50vw;

                @include media(">phone") {
                    max-width: unset;
                }
            }

            &--no-file-name {
                display: flex;
                align-items: center;
            }

            &-link {
                word-break: break-all;
                color: $boom-ho-gray;

                @include media(">phone") {
                    word-break: unset;
                }
            }

            .document-paragraph {
                @include poppins-medium(1.5rem);
                line-height: 2rem;
                margin-block-start: 1.5rem;
                margin-block-end: 1.5rem;
            }
        }

        &__button-wrapper {
            display: flex;
            align-items: center;
            justify-content: right;
            flex: 1 1 2.8rem;
            box-sizing: border-box;
            max-width: 2.8rem;
            min-width: 2.8rem;

            @include media(">phone") {
                display: flex;
                align-items: center;
                justify-content: right;
                flex: 1 1 10.8rem;
                box-sizing: border-box;
                max-width: 10.8rem;
                min-width: 10.8rem;
            }

            &__button-outline {
                outline: solid 0.2rem rgba(0, 36, 57, 0.16);
                border-radius: 0.8rem;
                height: 3rem;
                width: 3rem;
                color: $boom-ho-gray;
                font-size: 1.2rem;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;

                @include media(">phone") {
                    height: 5.2rem;
                    width: 5.2rem;
                    font-size: 2.4rem;
                }
            }

        }

        &__button {
            place-content: center;
            align-items: center;
            flex-direction: row;
            box-sizing: border-box;
            display: none;
            flex: 1 1 0%;

            @include media(">phone") {
                place-content: center;
                align-items: center;
                flex-direction: row;
                box-sizing: border-box;
                display: flex;
                flex: 1 1 14rem;
                min-width: 14rem;
                max-width: 14rem;
            }

            .button {
                max-height: 5.3rem;
            }
        }
    }

    &__reader,
    &__drillster {
        display: flex;
        position: absolute;
        flex: 1 1 0.6rem;
        max-width: 0.6rem;
        height: 9.6rem;
        align-items: center;

        .verticale__line {
            background-color: $boom-vo-primary;
            width: 0.5rem;
            height: 70%;
            border-radius: 0 2rem 2rem 0;
            margin-left: 0.25rem;
        }
    }
}

a:link {
    text-decoration: none;
    color: $boom-ho-secondary;
}

a:visited {
    text-decoration: none;
    color: $boom-ho-secondary;
}

a:hover {
    text-decoration: none;
    color: $boom-ho-secondary;
}

a:active {
    text-decoration: none;
    color: $boom-ho-secondary;
}

.down {
    -ms-transform: rotate(-90deg); /* IE 9 */
    transform: rotate(-90deg);
}

.right {
    -ms-transform: rotate(-180deg); /* IE 9 */
    transform: rotate(-180deg);
}
