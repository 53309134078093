@import '~assets/scss/variables';
@import '~assets/scss/mixins/border';

@mixin assignment-answer-model($color, $textColor: $boom-ho-white, $border-radius: 0.1rem, $attribute: data-label) {
    // Note: Do not forget to set data-label attribute, otherwise the label will not be displayed
    position: relative;
    border: 0.1rem solid $color;

    &:before {
        @include border-radius($border-radius);
        background-color: $color;
        content: attr(#{$attribute});
        white-space: nowrap;
        position: absolute;
        font-size: 0.75rem;
        text-transform: uppercase;
        display: block;
        line-height: 1.4rem;
        padding: 0 .4rem;
        top: -0.7rem;
        left: 0.4rem;
        color: $textColor;
    }
}
