@import '~assets/scss/variables';
@import '~assets/scss/mixins/color';
@import '~assets/scss/mixins/fonts';
@import '~assets/scss/mixins/border';

@mixin heading-modifiers {
    @each $name, $value in $color-map {
        &--color-#{$name} {
            color: $value;
        }
    }

    &--auto-margin {
        margin: auto 0;
    }

    &--no-margin {
        margin: 0;
    }

    &--flex {
        display: flex;
    }
}

@mixin heading-h1 {
    .heading-h1 {
        @include poppins-bold(3.2rem, 4rem);

        margin: 4.1rem 0 1.4rem 0;
        color: inherit;

        &--lg {
            @include poppins-bold(4.8rem, 5.6rem);
            margin-top: 3rem;
        }

        @include heading-modifiers;

        @content;
    }
}

@mixin heading-h2 {
    .heading-h2 {
        @include poppins-bold(1.6rem, 2.8rem);

        margin: 4.8rem 0 1.3rem;
        color: inherit;

        &--lg {
            @include poppins-bold(3.2rem, 4.8rem);
        }

        @include heading-modifiers;

        @content;
    }
}

@mixin heading-h3 {
    .heading-h3 {
        @include poppins-semi-bold(1.6rem, 2.4rem);

        margin: 3.2rem 0 0.8rem 0;
        color: inherit;

        &--lg {
            @include poppins-semi-bold(1.8rem, 3.2rem);
            margin-top: 4rem;
        }

        @include heading-modifiers;

        @content;
    }
}

@mixin paragraph {
    .paragraph {
        @include poppins-regular(1.6rem, 3.2rem);

        color: inherit;

        @content;
    }
}

@mixin span {
    .span {
        color: inherit;

        &--lowercase {
            text-transform: lowercase;
        }

        @content;
    }
}

@mixin list-unordered {
    .list-unordered {
        $root-element: &;
        color: inherit;

        &--inline {
            #{$root-element}__item {
                display: inline-flex;

                &:before {
                    content: '\2022';
                    margin: 0 0.9rem;
                }
            }
        }

        @content;
    }
}

@mixin overflow-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

@mixin heading-h3-content-mark-case($textColor: $boom-ho-black, $fontSize: 2rem, $marginBottom: 0rem) {
    .heading-h3 {
        @include heading-content-mark-case($textColor, $fontSize, $marginBottom) {
            @content;
        }
    }
}

@mixin heading-h4-content-mark-case($textColor: $boom-ho-black, $fontSize: 2rem, $marginBottom: 0rem) {
    .heading-h4 {
        @include heading-content-mark-case($textColor, $fontSize, $marginBottom) {
            @content;
        }
    }
}

@mixin heading-h5-content-mark-case($textColor: $boom-ho-black, $fontSize: 1.6rem, $marginBottom: 0rem) {
    .heading-h5 {
        @include heading-content-mark-case($textColor, $fontSize, $marginBottom) {
            @content;
        }
    }
}

@mixin heading-content-mark-case($textColor, $fontSize, $marginBottom) {
        display: flex;
        text-align: center;
        @include poppins-bold($fontSize);
        margin-bottom: $marginBottom;
        color: $textColor;

        @include heading-modifiers;

        @content;
}

@mixin label-style {
    @include border-radius(0.3rem);
    content: attr(data-label);
    white-space: nowrap;
    position: absolute;
    font-size: 0.75rem;
    text-transform: uppercase;
    display: block;
    line-height: 1.4rem;
    padding: 0 .4rem;
    top: -0.7rem;
    left: 0.4rem;
    color: $boom-ho-white;
    z-index: 1;
}
